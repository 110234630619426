import * as React from 'react'

import IconClock from '../../../images/icons/icon-clock.svg'
import IconBackArrow from '../../../images/icons/icon-back-arrow.svg'

import * as S from './styled'

const RepairDetailTextComponent = (props) => {
  function handleCloseRepairDetail() {
    props.onCloseRepairDetail()
    window.enableScroll()
  }

  const handleNavigation = (navigateTo) => {
    props.onNavigate(navigateTo)
  }

  return (
    <S.RepairDetailTextContainer>
      <S.RepairDetailTextIconClose
        data-home-repairs-option-button-close
        onClick={handleCloseRepairDetail}
      />
      <S.RepairDetailTextContentWrapper>
        <S.RepairDetailTextSubtitleMobile>
          REPAROS
        </S.RepairDetailTextSubtitleMobile>
        <S.RepairDetailTextIconPlay
          isextremerepair={props.isExtremeRepair}
          onClick={() => {
            handleNavigation('previous')
          }}
        />
        <S.RepairDetailTextIconPlay
          isextremerepair={props.isExtremeRepair}
          onClick={() => {
            handleNavigation('next')
          }}
        />
        <S.RepairDetailTextSubtitleDesktop>
          REPAROS
        </S.RepairDetailTextSubtitleDesktop>
        <S.RepairDetailTextTitle>{props.title}</S.RepairDetailTextTitle>
        <S.RepairDetailTextTitleMobile>
          {props.titleMobile}
        </S.RepairDetailTextTitleMobile>
        <S.RepairDetailTextText>{props.text}</S.RepairDetailTextText>

        {props.serviceTime && (
          <S.RepairDetailTextWaitTimeContainer>
            <IconClock />
            <S.RepairDetailTextWaitTimeTextContainer>
              <S.RepairDetailTextWaitTimeText>
                Tempo de serviço
              </S.RepairDetailTextWaitTimeText>
              <S.RepairDetailTextWaitTimeValue>
                {props.serviceTime}
              </S.RepairDetailTextWaitTimeValue>
            </S.RepairDetailTextWaitTimeTextContainer>
          </S.RepairDetailTextWaitTimeContainer>
        )}
        <S.RepairDetailAccordionCloseBtn
          data-home-repairs-option-button-close
          onClick={handleCloseRepairDetail}
        >
          <IconBackArrow />
          Voltar
        </S.RepairDetailAccordionCloseBtn>
      </S.RepairDetailTextContentWrapper>
    </S.RepairDetailTextContainer>
  )
}

export default RepairDetailTextComponent
