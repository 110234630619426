import styled from 'styled-components'

import media from '../../../styles/media-query'

export const HomeSupportWrapper = styled.section`
  padding: 100px;
  color: #386ba1;
  ${media.lessThan('sm')`
    padding: 50px 20px;
  `}
  ${media.lessThan('760px')`
    padding: 50px 0 0 0;
  `}
`

export const HomeSupportTitle = styled.h2`
  margin: 0 auto;
  text-align: center;
  color: #253556;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  ${media.lessThan('sm')`
    margin-bottom: 42px;
    font-size: 1.5em;
  `}
`

export const HomeSupportSubtitle = styled.h3`
  line-height: 1.2;
  margin-bottom: 12px;
  width: 100%;
  font-size: 1.4em;
  ${media.lessThan('sm')`
    font-size: 1.5em;
  `}
`

export const HomeSupportParagraph = styled.p`
  line-height: 1.2;
  ${media.lessThan('sm')`
    font-size: 0.875em;
  `}
`

export const HomeSupportGrid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1.1fr 0.9fr 1fr;
  grid-template-areas:
    'desc1 largeImage1 largeImage1'
    'ticketIcon ticket ticket'
    'desc2 largeImage2 largeImage2'
    'smallImage1 desc3 smallImage2'
    'nothing verticalImage1 nothing2'
    'gray verticalImage1 desc4';

  ${media.lessThan('lg')`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'desc1 desc1 largeImage1'
      'ticketIcon ticket ticket'
      'desc2 desc2 largeImage2'
      'smallImage2 desc3 smallImage1'
      'verticalImage1 desc3 gray'
      'verticalImage1 desc4 desc4';
  `}
  ${media.lessThan('sm')`
    grid-template-areas:
      'desc1 desc1 largeImage1'
      'ticketIcon ticket ticket'
      'desc2 desc2 largeImage2'
      'smallImage2 desc3 desc3'
      'gray gray verticalImage1'
      'desc4 desc4 verticalImage1';
  `}
  ${media.lessThan('1240px')`
    grid-template-areas:
      'desc1 desc1 largeImage1'
      'ticketIcon ticket ticket'
      'desc2 desc2 largeImage2'
      'smallImage2 desc3 desc3'
      'gray gray verticalImage1'
      'desc4 desc4 verticalImage1';
  `}
  ${media.lessThan('760px')`
    grid-template-areas:
      'largeImage1 largeImage1 largeImage1'
      'desc1 desc1 desc1'
      'ticketIcon ticketIcon ticketIcon'
      'ticket ticket ticket'
      'largeImage2 largeImage2 largeImage2'
      'desc2 desc2 desc2'
      'smallImage1 smallImage1 smallImage1'
      'desc3 desc3 desc3'
      'smallImage2 smallImage2 smallImage2'
      'desc4 desc4 desc4'
      'verticalImage1 verticalImage1 verticalImage1';
  `}

  .desc1 {
    grid-area: desc1;
    padding-top: 60px;
    padding-bottom: 60px;
    ${media.greaterThan('1160px')`
      min-height: 500px;
      display: flex;
      align-items: center;
    `}
  }
  .largeImage1 {
    grid-area: largeImage1;
  }
  .ticketIcon {
    grid-area: ticketIcon;
  }
  .ticket {
    grid-area: ticket;
  }
  .desc2 {
    grid-area: desc2;
    padding-top: 60px;
    padding-bottom: 60px;
    ${media.greaterThan('1160px')`
      min-height: 500px;
      display: flex;
      align-items: center;
    `}
  }
  .largeImage2 {
    grid-area: largeImage2;
  }
  .smallImage1 {
    grid-area: smallImage1;
  }
  .desc3 {
    grid-area: desc3;
  }
  .smallImage2 {
    grid-area: smallImage2;
  }
  .verticalImage1 {
    grid-area: verticalImage1;
  }
  .gray {
    grid-area: gray;
    background-color: #f4f4f4;
  }
  .desc4 {
    grid-area: desc4;
    background-color: #253556;
    color: #fff;
  }
  .nothing {
    ${media.greaterThan('1321px')`
      height: 260px;
    `}
  }
`

export const HomeSupportGridImageLarge = styled.div`
  overflow: hidden;
  position: relative;
  > div {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    height: 100%;
    transition: width 1s, height 1s;
    background-size: cover;
    background-image: url(${(props) => props.background});
  }

  &:hover > div {
    width: 130%;
    height: 130%;
  }

  ${media.lessThan('760px')`
    height: 235px;
  `}
`

export const HomeSupportGridImageLarge2 = styled(HomeSupportGridImageLarge)`
  > div {
    background-position: center;
  }
`

export const HomeSupportGridImageSmall = styled.div`
  overflow: hidden;
  position: relative;
  > div {
    position: absolute !important;
    top: 0;
    right: 0;
    /* transform: translate3d(-50%, -50%, 0); */
    width: 100%;
    height: 100%;
    transition: width 800ms;
    background-size: cover;
    background-image: url(${(props) => props.background});
  }

  &:hover > div {
    width: 120%;
  }

  ${media.lessThan('760px')`
    height: 235px;
  `}
`

export const HomeSupportGridImageSmall2 = styled(HomeSupportGridImageSmall)``

export const HomeSupportGridImageVertical = styled.div`
  overflow: hidden;
  position: relative;
  > div {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    transition: 800ms;
    background-position: center;
    background-size: cover;
    background-image: url(${(props) => props.background});
  }

  &:hover > div {
    width: 120%;
  }

  ${media.lessThan('760px')`
    height: 620px;
  `}
`

export const HomeSupportTicketIcon = styled.div`
  background-color: #386ba1;
  padding: 60px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const HomeSupportInfoTextContainer = styled.div`
  padding: 60px;

  ${media.lessThan('760px')`
    padding: 60px 25px 63px;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HomeSupportTicketContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  z-index: 1;
`

export const HomeSupportTicketContainerDecoration = styled.div`
  // eslint-disable-next-line prettier/prettier
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjE2MTEgMEwyMi4zMjIzIDExLjE2MTFMMTEuMTYxMSAyMi4zMjIzTDAgMTEuMTYxMUwxMS4xNjExIDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
  background-position: right -12px top;
  background-repeat: repeat-y;
  background-color: #253556;
  width: 60%;
  color: #fff;
  display: flex;
  padding: 30px 100px 30px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lessThan('lg')`
    width: 70%;
  `}
  ${media.lessThan('md')`
    width: 100%;
  `}
  ${media.lessThan('760px')`
    padding: 50px 25px 53px;
    align-items: flex-start;
    position: relative;
    background-image: none;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -12px;
      height: 20px;
      background-position: right bottom;
      background-repeat: repeat-x;
      // eslint-disable-next-line prettier/prettier
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjM2MjIgMTEuMzYyMUwxMS41IDIyLjIyNDNMMC42Mzc4OTkgMTEuMzYyMUwxMS41IDAuNTAwMDAyTDIyLjM2MjIgMTEuMzYyMVoiIGZpbGw9IiMyNTM1NTYiLz4KPC9zdmc+Cg==');
    }
  `}
`
