import styled from 'styled-components'

import media from '../../../styles/media-query'

export const RepairDetailWrapper = styled.section`
  background: linear-gradient(300deg, #386ba1 7.79%, #141930 85.83%);
  color: #ffffff;
  display: flex;
  overflow: hidden;
  padding: 80px 100px 100px 196px;
  position: relative;
  ${media.lessThan('sm')`
    flex-direction: column;
    padding: 25px;
  `}
`

export const RepairDetailImage = styled.div`
  right: 0;
  display: block;
  max-width: 1600px;
  top: 0;
  width: 100%;
  opacity: 0;
  ${(props) =>
    props.isCurrentStep &&
    `
    opacity: 1;
  `}
  ${media.lessThan('sm')`
    display: none;
  `}
`
export const RepairDetailImageMobile = styled.div`
  right: 0;
  bottom: 5%;
  display: none;
  margin-left: 30px;
  margin-top: -60px;
  width: 100%;

  ${media.lessThan('sm')`
    display: block;
  `}
`
