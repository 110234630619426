import styled from 'styled-components'

import media from '../../../styles/media-query'

export const HomeRepairsWrapper = styled.section`
  background: linear-gradient(
    0deg,
    #141930 3.895%,
    #386ba1 42.915%,
    #386ba1 48.8%,
    #141930 85.83%
  );
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIyMTU4IiB2aWV3Qm94PSIwIDAgMSAyMTU4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxMDgwIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxIDApIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXIpIi8+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEwODAiIHRyYW5zZm9ybT0ibWF0cml4KDEgMS43NDg0NmUtMDcgMS43NDg0NmUtMDcgLTEgLTkuMTU1MjdlLTA1IDIxNTgpIiBmaWxsPSJ1cmwoI3BhaW50MV9saW5lYXIpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXIiIHgxPSIwLjUiIHkxPSIxMDgwIiB4Mj0iMC41MTgwNzIiIHkyPSIxNTMiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agb2Zmc2V0PSIwLjA5MDc0MDciIHN0b3AtY29sb3I9IiMzODZCQTEiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMTQxOTMwIi8+CjwvbGluZWFyR3JhZGllbnQ+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQxX2xpbmVhciIgeDE9IjAuNSIgeTE9IjEwODAiIHgyPSIwLjUxODA3MiIgeTI9IjE1MyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBvZmZzZXQ9IjAuMDkwNzQwNyIgc3RvcC1jb2xvcj0iIzM4NkJBMSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMxNDE5MzAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K');
  background-repeat: repeat;
  background-position: 0 0; */
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  ${media.lessThan('sm')`
    padding-top: 100px;
  `}
  ${media.greaterThan('sm')`
    margin-left: -96px;
    margin-top: 0 !important;
  `}

  .hidden {
    display: none;
  }
  .pin-spacer {
    position: absolute !important;
  }
`

export const HomeRepairsContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  ${media.greaterThan('sm')`
    padding-top: 0 !important;
  `}
`

export const HomeRepairsHeader = styled.header`
  display: none;
  ${(props) =>
    props.show &&
    `
    display: flex;
    padding: 40px 0;
  `}
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  top: 0;
  left: calc(50% + 96px);
  transform: translateX(-50%);
  z-index: 1;
  ${media.lessThan('sm')`
    left: 50%;
    padding: 0 0;
    margin-bottom: 0;
  `}

  max-width: 720px;
  ${media.greaterThan('1200px')`
    max-width: 960px;
  `}
  ${media.greaterThan('1300px')`
    max-width: calc(100% - 296px);
  `}
`

export const HomeRepairsTitle = styled.h2`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 140px;
  ${media.lessThan('sm')`
    padding-left: 0;
  `}
`

export const HomeRepairsButton = styled.button`
  font-size: 1em;
  padding: 18px 60px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  transition: 200ms;
  font-weight: 700;
  cursor: pointer;
  z-index: 10;
  white-space: nowrap;
  &:focus,
  &:hover {
    background-color: #fff;
    color: #386ba1;
  }

  display: none;
  ${media.greaterThan('sm')`
    display: block;
  `}
`

export const HomeRepairsButtonSm = styled(HomeRepairsButton)`
  display: none;
  bottom: 40px;
  font-size: 1.25em;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  ${(props) =>
    props.show &&
    `
    display: block;
  `}
  ${media.greaterThan('sm')`
    display: none !important;
  `}
`

export const HomeRepairsBackground = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  top: 50%;
  ${media.greaterThan('sm')`
    top: 50% !important;
  `}
`

export const HomeRepairsOptions = styled.ul`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: none;
  z-index: 1;
  ${(props) =>
    props.show &&
    `
    display: block;
  `}
`

export const HomeRepairsOptionButton = styled.button`
  border: 0;
  background: transparent;
  color: currentColor;
  cursor: pointer;
  position: relative;
  padding: 10px;
  font-size: 1.2em;
  ${media.lessThan('sm')`
    font-size: 0.875em;
  `}
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background-color: currentColor;
    bottom: 6px;
    left: 10px;
    width: 0;
    transition: 200ms;
  }
  &:hover:after {
    width: calc(100% - 20px);
  }
`

export const HomeRepairsOption = styled.li`
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  ${media.greaterThan('1300px')`
    flex-direction: row;
  `}
`

export const HomeRepairsOptionDecoration = styled.div`
  padding: 10px 0;
  margin-bottom: -4px;
  > svg:first-child {
    display: none;
  }
  ${media.greaterThan('1300px')`
    padding: 0 10px;

    > svg:first-child {
      display: block;
    }
    > svg:last-child {
      display: none;
    }
  `}
`

export const HomeRepairsOptionScreen = styled(HomeRepairsOption)`
  top: -240px;
  left: -240px;
  @media (max-height: 760px) {
    top: -200px;
    left: -160px;
  }
  ${media.lessThan('sm')`
    top: -190px;
    left: -130px;
  `}
  ${media.greaterThan('1300px')`
    top: -140px;
    left: -400px;
    @media (max-height: 760px) {
      top: -100px;
    }
  `}
`

export const HomeRepairsOptionScreenDecoration = styled(
  HomeRepairsOptionDecoration
)``

export const HomeRepairsOptionCamera = styled(HomeRepairsOption)`
  flex-direction: column-reverse;
  left: 100px;
  top: -310px;
  @media (max-height: 760px) {
    left: 40px;
    top: -230px;
  }
  ${media.lessThan('sm')`
    left: 30px;
    top: -220px;
    @media (max-height: 760px) and (min-width: 600px) {
      left: 70px;
      top: -220px;
    }
  `}
  ${media.greaterThan('1300px')`
    flex-direction: row;

    left: 150px;
    top: -200px;
    @media (max-height: 760px) {
      left: 100px;
      top: -150px;
    }
  `}
`

export const HomeRepairsOptionCameraDecoration = styled(
  HomeRepairsOptionDecoration
)``

export const HomeRepairsOptionConector = styled(HomeRepairsOption)`
  flex-direction: column-reverse;

  bottom: -310px;
  left: -310px;
  @media (max-height: 760px) {
    bottom: -260px;
    left: -240px;
  }
  ${media.lessThan('sm')`
    bottom: -220px;
    left: -185px;
    @media (max-height: 760px) and (min-width: 600px) {
      bottom: -240px;
      left: -220px;
    }
  `}
  ${media.greaterThan('1300px')`
    flex-direction: row;

    /* top: 150px;
    left: -480px; */
    top: 40px;
    left: -540px;
    @media (max-height: 760px) {
      top: -80px;
      left: -480px;
    }
  `}
`

export const HomeRepairsOptionConectorDecoration = styled(
  HomeRepairsOptionDecoration
)``

export const HomeRepairsOptionBattery = styled(HomeRepairsOption)`
  flex-direction: column !important;
  top: 30px;
  left: 50%;

  ${media.lessThan('sm')`
    top: -30px;
    left: -20px;
  `}
  ${media.greaterThan('1300px')`
    top: -60px;
    left: 50%;
  `}
`

export const HomeRepairsOptionBatteryDecoration = styled(
  HomeRepairsOptionDecoration
)`
  padding: 10px 0;
  margin-bottom: 0;
`

export const HomeRepairsOptionBackCamera = styled(HomeRepairsOption)`
  left: 150px;
  top: -10px;
  @media (max-height: 760px) {
    left: 100px;
  }
  ${media.lessThan('sm')`
    left: 50px;
    top: -10px;
  `}
  ${media.greaterThan('1300px')`
    left: 200px;
    top: -10px;
    @media (max-height: 760px) {
      left: 150px;
    }
  `}
`

export const HomeRepairsOptionBackCameraDecoration = styled(
  HomeRepairsOptionDecoration
)``

export const HomeCoverIphone = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
`

export const HomeCoverIphoneAnimation = styled.div`
  position: relative;
  width: 1px;
`

export const HomeCoverIphoneAnimationSteps = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 640px;
  max-width: 87%;
  height: auto;
  ${media.lessThan('sm')`
    width: 440px;
  `}
  ${media.lessThan('600px')`
    width: 326px;
  `}

  @keyframes float {
    0% {
      transform: translate3d(-50%, -50%, 0);
    }
    50% {
      transform: translate3d(-50%, calc(-50% - 20px), 0);
    }
    100% {
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &.floating {
    animation: float 6s ease-in-out infinite;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-image: url(${(props) => props.background});
    ${media.lessThan('sm')`
      transform: translate3d(-50%, -50%, 0) scale(0.6985);
    `}
    @media (max-height: 760px) {
      transform: translate3d(-50%, -50%, 0) scale(0.65);
    }

    ${media.lessThan('600px')`
      transform: translate3d(-50%, -50%, 0) scale(0.5006);
    `}

    &.bg-step-0 {
      width: 640px;
      height: 763px;
      background-position: -10px -10px;
    }

    &.bg-step-1 {
      width: 640px;
      height: 759px;
      background-position: -670px -10px;
    }

    &.bg-step-2 {
      width: 640px;
      height: 743px;
      background-position: -1330px -10px;
    }

    &.bg-step-3 {
      width: 640px;
      height: 719px;
      background-position: -10px -793px;
    }

    &.bg-step-4 {
      width: 640px;
      height: 687px;
      background-position: -670px -793px;
    }

    &.bg-step-5 {
      width: 640px;
      height: 653px;
      background-position: -1330px -793px;
    }

    &.bg-step-6 {
      width: 640px;
      height: 613px;
      background-position: -1990px -10px;
    }

    &.bg-step-7 {
      width: 640px;
      height: 579px;
      background-position: -1990px -643px;
    }

    &.bg-step-8 {
      width: 640px;
      height: 548px;
      background-position: -10px -1532px;
    }

    &.bg-step-9 {
      width: 640px;
      height: 516px;
      background-position: -670px -1532px;
    }

    &.bg-step-10 {
      width: 640px;
      height: 486px;
      background-position: -670px -2100px;
    }

    &.bg-step-11 {
      width: 640px;
      height: 467px;
      background-position: -2650px -999px;
    }

    &.bg-step-12 {
      width: 640px;
      height: 452px;
      background-position: -10px -2608px;
    }

    &.bg-step-13 {
      width: 640px;
      height: 441px;
      background-position: -1330px -2608px;
    }

    &.bg-step-14 {
      width: 640px;
      height: 433px;
      background-position: -2650px -2608px;
    }

    &.bg-step-15 {
      width: 640px;
      height: 430px;
      background-position: -3310px -462px;
    }

    &.bg-step-16 {
      width: 640px;
      height: 430px;
      background-position: -3310px -912px;
    }

    &.bg-step-17 {
      width: 640px;
      height: 432px;
      background-position: -3310px -10px;
    }

    &.bg-step-18 {
      width: 640px;
      height: 440px;
      background-position: -1990px -2608px;
    }

    &.bg-step-19 {
      width: 640px;
      height: 449px;
      background-position: -670px -2608px;
    }

    &.bg-step-20 {
      width: 640px;
      height: 457px;
      background-position: -2650px -1971px;
    }

    &.bg-step-21 {
      width: 640px;
      height: 465px;
      background-position: -2650px -1486px;
    }

    &.bg-step-22 {
      width: 640px;
      height: 472px;
      background-position: -2650px -507px;
    }

    &.bg-step-23 {
      width: 640px;
      height: 477px;
      background-position: -2650px -10px;
    }

    &.bg-step-24 {
      width: 640px;
      height: 481px;
      background-position: -1990px -2100px;
    }

    &.bg-step-25 {
      width: 640px;
      height: 485px;
      background-position: -1330px -2100px;
    }

    &.bg-step-26 {
      width: 640px;
      height: 488px;
      background-position: -10px -2100px;
    }

    &.bg-step-27 {
      width: 640px;
      height: 489px;
      background-position: -1990px -1532px;
    }

    &.bg-step-28 {
      width: 640px;
      height: 490px;
      background-position: -1330px -1532px;
    }
  }
`

export const HomeCoverLeftTitle = styled.div`
  position: absolute;
  top: -140px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: -10%;
  margin-left: -16%;
  font-weight: 400;
  width: 41%;
  svg {
    width: 100%;
    height: auto;
    /* transition: 600ms; */
  }

  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    overflow: hidden;
    width: 0;
    height: 0;
  `}
  ${media.greaterThan('sm')`
    top: 50%;
  `}
  ${media.lessThan('sm')`
    top: -240px;
  `}
`

export const HomeCoverRightTitle = styled.div`
  position: absolute;
  top: -160px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: 10%;
  margin-left: 14%;
  width: 45%;
  svg {
    width: 100%;
    height: auto;
    /* transition: 600ms; */
  }

  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    overflow: hidden;
    width: 0;
    height: 0;
  `}
  ${media.greaterThan('sm')`
    top: 50%;
  `}
  ${media.lessThan('sm')`
    top: -260px;
  `}
`

export const HomeCoverLeftTitleAfter = styled(HomeCoverLeftTitle)`
  margin-left: -2%;
  width: 60%;
`

export const HomeCoverRightTitleAfter = styled(HomeCoverRightTitle)`
  width: 50%;
`

export const HomeCoverScrollIcon = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 1;
  top: calc(50vh - 100px);
  position: absolute;
  width: 80px;
  height: 80px;
  ${media.lessThan('sm')`
    transform: translateX(-50%) scale(0.8);
  `}
  ${media.greaterThan('sm')`
    margin-top: 0 !important;
  `}

  background: rgba(37, 53, 86, 0.5);
  border-radius: 40px;
  transition: 400ms;

  ${(props) =>
    props.hide &&
    `
    opacity: 0;
    overflow: hidden;
    width: 0;
    height: 0;
  `}
  .scroll-downs {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    left: 0;
    margin: auto;
    width: 20px;
    height: 32px;
    &:after,
    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 3px;
      border-radius: 4px;
      background-color: #ffffff;
      position: absolute;
      bottom: -10px;
    }
    &:after {
      left: 6px;
      transform: rotate(-60deg);
    }
    &:before {
      right: 4px;
      transform: rotate(60deg);
    }
  }
  .mousey {
    width: 3px;
    padding: 1px 6px;
    height: 24px;
    border: 3px solid #fff;
    border-radius: 25px;
    box-sizing: content-box;
  }
  .scroller {
    margin-top: 3px;
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  }
`
