import * as React from 'react'

import IconArrowDown from '../../../../images/icons/icon-arrow-accordion-down.svg'

import * as S from './styled'

const AccordionItemComponent = (props) => {
  const accordionContent = React.useRef(null)

  const toggleAccordion = () => {
    props.onToggleAccordion()
  }

  const height =
    props.active && accordionContent.current
      ? `${accordionContent.current.scrollHeight}px`
      : '0px'

  return (
    <S.AccordionItemContainer>
      <S.AccordionItemTitle active={props.active} onClick={toggleAccordion}>
        {props.title}
        <IconArrowDown />
      </S.AccordionItemTitle>
      <S.AccordionItemText ref={accordionContent} maxHeight={height}>
        {props.text}
      </S.AccordionItemText>
    </S.AccordionItemContainer>
  )
}

export default AccordionItemComponent
