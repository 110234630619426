import * as React from 'react'

import AccordionItemComponent from './components/AccordionItem/index'

import IconBackArrow from '../../images/icons/icon-back-arrow.svg'

import * as S from './styled'

const ServicesComponent = ({
  isServicesOpen,
  onCloseServices,
  repairsAccordion
}) => {
  const handleCloseServices = () => {
    window.enableScroll()
    onCloseServices()
  }

  const [activeItem, setActiveItem] = React.useState('0-0')

  const changeActiveItem = (toChange) => {
    if (toChange === activeItem) return
    setActiveItem(toChange)
  }

  return (
    <S.ServicesWrapper
      isServicesOpen={isServicesOpen}
      className="fixed-element"
    >
      {repairsAccordion.map((accordion, accordionIndex) => (
        <S.ServicesContainer key={accordionIndex}>
          <S.ServicesTitleWrapper>
            <S.ServicesTitle>
              {accordion.title}
              {accordionIndex === 0 && (
                <S.ServicesAccordionCloseBtn
                  data-home-repairs-option-button-close
                  onClick={handleCloseServices}
                >
                  <IconBackArrow />
                  Voltar
                </S.ServicesAccordionCloseBtn>
              )}
              <S.ServicesAccordionCloseBtn
                data-home-repairs-option-button-close
                onClick={handleCloseServices}
                mobile
              >
                <S.RepairDetailTextIconClose />
              </S.ServicesAccordionCloseBtn>
            </S.ServicesTitle>
            <S.ServicesSubtext>{accordion.description}</S.ServicesSubtext>
          </S.ServicesTitleWrapper>

          <S.ServicesAccordionWrapper>
            {accordion.items.map((item, itemIndex) => (
              <AccordionItemComponent
                title={item.title}
                text={item.description}
                active={activeItem === `${accordionIndex}-${itemIndex}`}
                key={itemIndex}
                onToggleAccordion={() =>
                  changeActiveItem(`${accordionIndex}-${itemIndex}`)
                }
              />
            ))}
          </S.ServicesAccordionWrapper>
        </S.ServicesContainer>
      ))}
    </S.ServicesWrapper>
  )
}

export default ServicesComponent
