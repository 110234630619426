import styled from 'styled-components'

import FrontalCamera from './FrontalCamera/index'
import ChargeConector from './ChargeConector/index'
import BatteryChange from './BatteryChange/index'
import BrokenScreen from './BrokenScreen/index'
import RearCamera from './RearCamera/index'

import media from '../../styles/media-query'

export const RepairDetailWrapper = styled.section`
  color: #ffffff;
  height: 100vh;
  min-width: 100%;
  overflow: auto;
  position: absolute;
  transform: translate3d(0, -200%, 0);
  transition: all 0.3s;
  top: 0;
  left: 0;
  z-index: 1000;
  ${media.lessThan('sm')`
    flex-direction: column;
    height: 100vh;
  `}
  opacity: 0;

  ${(props) =>
    props.isRepairOpen &&
    `
      opacity: 1;
      transform: translate3d(0, 0, 0);
  `}
`

export const RepairDetailFrontalCamera = styled(FrontalCamera)`
  min-height: 100vh;
  min-width: 100%;
  ${(props) =>
    !props.active &&
    `
      display: none;
    `};
`

export const RepairDetailChargeConector = styled(ChargeConector)`
  min-height: 100vh;
  min-width: 100%;
  ${(props) =>
    !props.active &&
    `
      display: none;
    `}
`

export const RepairDetailBatteryChange = styled(BatteryChange)`
  min-height: 100vh;
  min-width: 100%;
  ${(props) =>
    !props.active &&
    `
      display: none;
    `}
`

export const RepairDetailBrokenScreen = styled(BrokenScreen)`
  min-height: 100vh;
  min-width: 100%;
  ${(props) =>
    !props.active &&
    `
      display: none;
    `}
`

export const RepairDetailRearCamera = styled(RearCamera)`
  min-height: 100vh;
  min-width: 100%;
  ${(props) =>
    !props.active &&
    `
      display: none;
    `}
`
