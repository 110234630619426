import * as React from 'react'

import IconCrown from '../../../images/home/support/icon-crown.svg'

import * as S from './styled'

const HomeSupportComponent = ({ supportContent }) => {
  return (
    <S.HomeSupportWrapper id="atendimento">
      <S.HomeSupportTitle className="gs_reveal">Atendimento</S.HomeSupportTitle>

      <S.HomeSupportGrid>
        <S.HomeSupportInfoTextContainer className="desc1">
          <S.HomeSupportSubtitle className="text gs_reveal gs_reveal_fromLeft">
            {supportContent && supportContent[0] ? supportContent[0].title : ''}
          </S.HomeSupportSubtitle>
          <S.HomeSupportParagraph className="text gs_reveal gs_reveal_fromLeft">
            {supportContent && supportContent[0]
              ? supportContent[0].description
              : ''}
          </S.HomeSupportParagraph>
        </S.HomeSupportInfoTextContainer>

        <S.HomeSupportGridImageLarge
          className="largeImage1 gs_reveal gs_reveal_fromRight"
          background={
            supportContent && supportContent[0] ? supportContent[0].image : ''
          }
        >
          <div />
        </S.HomeSupportGridImageLarge>

        <S.HomeSupportTicketIcon className="ticketIcon">
          <IconCrown />
        </S.HomeSupportTicketIcon>

        <S.HomeSupportTicketContainer className="ticket">
          <S.HomeSupportTicketContainerDecoration>
            <S.HomeSupportSubtitle>
              {supportContent && supportContent[1]
                ? supportContent[1].title
                : ''}
            </S.HomeSupportSubtitle>
            <S.HomeSupportParagraph>
              {supportContent && supportContent[1]
                ? supportContent[1].description
                : ''}
            </S.HomeSupportParagraph>
          </S.HomeSupportTicketContainerDecoration>
        </S.HomeSupportTicketContainer>

        <S.HomeSupportInfoTextContainer className="desc2">
          <S.HomeSupportSubtitle className="gs_reveal gs_reveal_fromLeft">
            {supportContent && supportContent[2] ? supportContent[2].title : ''}
          </S.HomeSupportSubtitle>
          <S.HomeSupportParagraph className="gs_reveal gs_reveal_fromLeft">
            {supportContent && supportContent[2]
              ? supportContent[2].description
              : ''}
          </S.HomeSupportParagraph>
        </S.HomeSupportInfoTextContainer>

        <S.HomeSupportGridImageLarge2
          className="largeImage2 gs_reveal gs_reveal_fromRight"
          background={
            supportContent && supportContent[2] ? supportContent[2].image : ''
          }
        >
          <div />
        </S.HomeSupportGridImageLarge2>

        <S.HomeSupportGridImageSmall
          className="smallImage1 gs_reveal gs_reveal_fromLeft"
          background={
            supportContent && supportContent[3] ? supportContent[3].image_1 : ''
          }
        >
          <div />
        </S.HomeSupportGridImageSmall>

        <S.HomeSupportInfoTextContainer className="desc3">
          <S.HomeSupportSubtitle className="gs_reveal">
            {supportContent && supportContent[3] ? supportContent[3].title : ''}
          </S.HomeSupportSubtitle>
          <S.HomeSupportParagraph className="gs_reveal">
            {supportContent && supportContent[3]
              ? supportContent[3].description
              : ''}
          </S.HomeSupportParagraph>
        </S.HomeSupportInfoTextContainer>

        <S.HomeSupportGridImageSmall2
          className="smallImage2 gs_reveal gs_reveal_fromRight"
          background={
            supportContent && supportContent[3] ? supportContent[3].image_2 : ''
          }
        >
          <div />
        </S.HomeSupportGridImageSmall2>

        <S.HomeSupportGridImageVertical
          className="verticalImage1 gs_reveal"
          background={
            supportContent && supportContent[4] ? supportContent[4].image : ''
          }
        >
          <div />
        </S.HomeSupportGridImageVertical>

        <div className="gray" />
        <div className="nothing" />
        <div className="nothing2" />

        <S.HomeSupportInfoTextContainer className="desc4">
          <S.HomeSupportSubtitle className="gs_reveal gs_reveal_fromRight">
            {supportContent && supportContent[4] ? supportContent[4].title : ''}
          </S.HomeSupportSubtitle>
          <S.HomeSupportParagraph className="gs_reveal gs_reveal_fromRight">
            {supportContent && supportContent[4]
              ? supportContent[4].description
              : ''}
          </S.HomeSupportParagraph>
        </S.HomeSupportInfoTextContainer>
      </S.HomeSupportGrid>
    </S.HomeSupportWrapper>
  )
}

export default HomeSupportComponent
