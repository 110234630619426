import styled from 'styled-components'
import IconPlay from '../../../images/icons/icon-play.svg'
import IconClose from '../../../images/icons/icon-close.svg'

import media from '../../../styles/media-query'

export const RepairDetailTextContainer = styled.div`
  max-width: 1200px;
  z-index: 1000;
  user-select: none;
`

export const RepairDetailTextContentWrapper = styled.div`
  max-width: 335px;
  ${media.lessThan('sm')`
    max-width: 500px;
  `}
`

export const RepairDetailAccordionCloseBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  height: 60px;
  justify-content: center;
  margin-top: 40px;
  padding: 0 32px;
  transition: 200ms;

  > svg {
    margin-right: 16px;
  }

  &:focus,
  &:hover {
    background-color: #fff;
    color: #386ba1;
    svg {
      path {
        fill: #386ba1;
      }
    }
  }

  &:focus {
    outline: none;
  }

  ${media.lessThan('sm')`
    display: none;
  `}
`

export const RepairDetailTextIconPlay = styled(IconPlay)`
  cursor: pointer;
  width: 50px;
  &:first-of-type {
    transform: rotate(180deg);
    margin-right: 10px;
    ${(props) =>
      props.isextremerepair === 'first' &&
      `
        opacity: 0.3;
        cursor: auto;
      `}
  }
  &:not(:first-of-type) {
    ${(props) =>
      props.isextremerepair === 'last' &&
      `
        cursor: auto;
        opacity: 0.3;
      `}
  }
  ${media.lessThan('sm')`
    height: 40px;
    margin-top: 40px;
    width: 40px;
  `}
`

export const RepairDetailTextIconClose = styled(IconClose)`
  position: absolute;
  right: 24px;
  top: 60px;
  ${media.greaterThan('sm')`
    display: none;
  `}
`

const RepairDetailTextSubtitle = styled.h2`
  font-size: 1em;
  margin-top: 40px;
`

export const RepairDetailTextSubtitleDesktop = styled(RepairDetailTextSubtitle)`
  ${media.lessThan('sm')`
    display: none;
  `}
`

export const RepairDetailTextSubtitleMobile = styled(RepairDetailTextSubtitle)`
  margin-top: 10px;
  ${media.greaterThan('sm')`
    display: none;
  `}
`

export const RepairDetailTextTitle = styled.h1`
  font-size: 2.6em;
  margin-top: 16px;
  ${media.lessThan('sm')`
    display: none;
  `}
`

export const RepairDetailTextTitleMobile = styled.h1`
  font-size: 2.25em;
  margin-top: 16px;
  ${media.greaterThan('sm')`
    display: none;
  `}
`

export const RepairDetailTextText = styled.p`
  margin-top: 24px;
  line-height: 1.4;
  ${media.lessThan('sm')`
    font-size: 0.75em;
    margin-top: 12px;
  `}
`

export const RepairDetailTextWaitTimeContainer = styled.div`
  display: flex;
  margin-top: 32px;
`

export const RepairDetailTextWaitTimeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 16px;
`

export const RepairDetailTextWaitTimeText = styled.span`
  font-size: 1em;
  ${media.lessThan('sm')`
    font-size: 0.875em;
  `}
`

export const RepairDetailTextWaitTimeValue = styled.strong`
  font-size: 1.5em;
  font-weight: 700;
  ${media.lessThan('sm')`
    font-size: 1.25em;
  `}
`
