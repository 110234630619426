import * as React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import * as S from './styled'

const AdressesComponent = () => {
  const {
    desktopStaEfigenia,
    desktopJoaoCachoeira,
    mobileStaEfigenia,
    mobileJoaoCachoeira
  } = useStaticQuery(
    graphql`
      query {
        desktopStaEfigenia: file(
          relativePath: { eq: "home/addresses/desktop-sta-efigenia.png" }
        ) {
          publicURL
        }
        desktopJoaoCachoeira: file(
          relativePath: { eq: "home/addresses/desktop-joao-cachoeira.png" }
        ) {
          publicURL
        }
        mobileStaEfigenia: file(
          relativePath: { eq: "home/addresses/mobile-sta-efigenia.png" }
        ) {
          publicURL
        }
        mobileJoaoCachoeira: file(
          relativePath: { eq: "home/addresses/mobile-joao-cachoeira.png" }
        ) {
          publicURL
        }
      }
    `
  )

  const [activeSelectorIndex, setActiveSelectorIndex] = React.useState(0)

  const setActive = (newIndex) => {
    if (activeSelectorIndex === newIndex) {
      return
    }
    setActiveSelectorIndex(newIndex)
  }

  const toggleContact = () => {
    navigate('/contato')
  }

  const goToMaps = () => {
    window.open(
      activeSelectorIndex === 0
        ? 'https://g.page/ReidoiPhoneSP?share'
        : 'https://goo.gl/maps/RfQuucvAhq8At7H4A'
    )
  }

  const goToWhatsapp = () => {
    window.open('http://wa.me/5511945580443')
  }

  return (
    <S.AdressesWrapper id="contato">
      <S.AdressesHeaderWrapper>
        <S.AdressesTitle>Venha conhecer nossa loja.</S.AdressesTitle>
        <S.AdressesWhatsappTextDesktop
          onClick={() => {
            goToWhatsapp()
          }}
        >
          <S.AdressesWhatsappIcon />
          11 94558-0443
        </S.AdressesWhatsappTextDesktop>
      </S.AdressesHeaderWrapper>
      <S.AdressesContentWrapper>
        <S.AdressesContactComponent
          onCloseContact={() => {
            toggleContact()
          }}
        />
        <S.AdressesContainer>
          <S.AdressesMapDesktop
            backgroundImage={
              activeSelectorIndex === 0
                ? desktopStaEfigenia.publicURL
                : desktopJoaoCachoeira.publicURL
            }
            onClick={() => {
              goToMaps()
            }}
          />
          <S.AdressesMapMobile
            backgroundImage={
              activeSelectorIndex === 0
                ? mobileStaEfigenia.publicURL
                : mobileJoaoCachoeira.publicURL
            }
            onClick={() => {
              goToMaps()
            }}
          />
          <S.AdressesSelectionContainer>
            <S.AdressesSelectionSelector
              active={0 === activeSelectorIndex}
              onClick={() => setActive(0)}
            >
              <S.AdressesSelectionSelectorTitle>
                <span className="desktop">Rei do iPhone - </span>
                Loja Sta Efigênia
              </S.AdressesSelectionSelectorTitle>
              <S.AdressesSelectionSelectorLocation>
                R. Santa Ifigênia, 78
                <span className="desktop"> - Santa Ifigênia</span>
              </S.AdressesSelectionSelectorLocation>
              <S.AdressesSelectionSelectorCity>
                <span className="desktop">São Paulo/SP - 01207-001</span>
                <span className="mobile">Santa Ifigênia - SP</span>
              </S.AdressesSelectionSelectorCity>
            </S.AdressesSelectionSelector>

            <S.AdressesSelectionSelector
              active={1 === activeSelectorIndex}
              onClick={() => setActive(1)}
            >
              <S.AdressesSelectionSelectorTitle>
                <span className="desktop">Rei do iPhone - </span>
                Loja João Cachoeira
              </S.AdressesSelectionSelectorTitle>
              <S.AdressesSelectionSelectorLocation>
                R. João Cachoeira, 567
                <span className="desktop"> - Itaim Bibi</span>
              </S.AdressesSelectionSelectorLocation>
              <S.AdressesSelectionSelectorCity>
                <span className="desktop">São Paulo/SP - 04535-011</span>
                <span className="mobile">Itaim Bibi - SP</span>
              </S.AdressesSelectionSelectorCity>
            </S.AdressesSelectionSelector>
          </S.AdressesSelectionContainer>

          <S.AdressesWhatsappTextMobile
            onClick={() => {
              goToWhatsapp()
            }}
          >
            <S.AdressesWhatsappIcon />
            11 94558-0443
          </S.AdressesWhatsappTextMobile>

          <S.AdressesButtonMobile
            onClick={(e) => {
              e.preventDefault()
              toggleContact()
            }}
          >
            Fale com a gente
          </S.AdressesButtonMobile>
        </S.AdressesContainer>
      </S.AdressesContentWrapper>
    </S.AdressesWrapper>
  )
}

export default AdressesComponent
