import styled from 'styled-components'

import media from '../../styles/media-query'

export const MenuWrapper = styled.nav`
  --menu-background-color: #ffffff;
  --menu-text-color: #253556;
  --menu-social-color: #3274b6;

  ${(props) =>
    props.isDarkMenu &&
    `
    --menu-background-color: #386BA1;
    --menu-text-color: #ffffff;
    --menu-social-color: #ffffff;
  `}

  background-color: var(--menu-background-color);
  color: var(--menu-text-color);
  user-select: none;
  position: fixed;
  left: 0;
  width: 98px;
  height: 100vh;
  grid-template-rows: 180px auto;
  transition: color 0.5s, background-color 0.5s,
    transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  z-index: 10000;
  ${(props) =>
    props.hide &&
    `
    transform: translate3d(-110%, 0, 0);
  `}

  display: none;
  ${media.greaterThan('sm')`
    display: grid;
  `}
`

export const MenuBrand = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`

export const MenuBrandImage = styled.div`
  cursor: pointer;
  ${media.greaterThan('sm')`
    color: #1c75bc;

    ${(props) =>
      props.isDarkMenu &&
      `
      color: white;
    `}
  `}

  .logo-desktop {
    display: block;
    position: relative;

    > svg {
      position: relative;
      z-index: 1;
    }

    .logo-desktop-text {
      position: absolute;
      left: -15px;
      top: -20px;
      bottom: -25px;
      color: #253556;
      background: #fff;
      border-radius: 8px;

      opacity: 0;
      padding: 20px 0;
      box-shadow: 0;
      width: 0;
      overflow: hidden;
      transition: 200ms;
    }

    ${(props) =>
      !props.hide &&
      `
      &:hover {
        color: #1c75bc;
        .logo-desktop-text {
          opacity: 1;
          padding: 20px 24px 20px 92px;
          width: auto;
          box-shadow: 0px 4px 8px rgba(56, 107, 161, 0.17);
        }
      }
    `}
  }
`

export const MenuCollapse = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: auto;
  margin-bottom: auto;

  ${media.lessThan('sm')`
    padding: 60px 30px;
    align-items: flex-start;
  `}
`

export const MenuItems = styled.ul``

export const MenuItem = styled.li`
  width: 40px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  a {
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: var(--menu-text-color);
    text-decoration: none;
    border-radius: 8px;
    transition: 200ms;
    position: relative;

    > div {
      color: currentColor;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      position: relative;
      z-index: 2;
    }
    > span {
      width: 0;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      background-color: var(--menu-text-color);
      top: 0;
      border-radius: 8px;
      bottom: 0;
      overflow: hidden;
      transition: 200ms;
      opacity: 0;
    }
    &.active {
      background-color: var(--menu-text-color);
      color: var(--menu-background-color);
    }
    &:hover {
      color: var(--menu-background-color);
      > span {
        padding-left: 50px;
        padding-right: 18px;
        opacity: 1;
        width: auto;
        box-shadow: 0px 4px 8px rgba(56, 107, 161, 0.17);
      }
    }
  }
`

export const MenuItemIconWrapper = styled.div``

export const MenuItemText = styled.span`
  overflow: hidden;
`

export const MenuSocial = styled.div`
  align-self: center;
  margin-top: auto;
  margin-bottom: 20px;
`

export const MenuSocialItems = styled.ul``

export const MenuSocialItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  a {
    display: flex;
    align-items: center;
    color: var(--menu-social-color);
    text-decoration: none;
    position: relative;
    > svg {
      transition: 600ms;
    }
    > svg.social-icon-fill {
      position: absolute;
      opacity: 0;
    }
    &:hover {
      > svg:not(.social-icon-fill) {
        opacity: 0;
      }
      > svg.social-icon-fill {
        opacity: 1;
      }
    }
  }
`
