import styled from 'styled-components'

export const AccordionItemContainer = styled.div`
  padding: 22px 0;
  border-top: 1px solid #ffffff4c;
  &:last-child {
    border-bottom: 1px solid #ffffff4c;
  }
`

export const AccordionItemTitle = styled.h3`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  font-weight: 800;
  margin-bottom: 8px;
  > svg {
    transition: all 0.3s;
    ${(props) =>
      props.active &&
      `
      transform: rotate(180deg);
      path {
        fill: #ffffff66;
      }
    `}
  }
`

export const AccordionItemText = styled.p`
  font-size: 1em;
  overflow: hidden;
  max-height: 100px;
  transition: all 0.3s;
  line-height: 1.4;
  ${(props) =>
    props.maxHeight &&
    `
      max-height: ${props.maxHeight};
    `}
  ${(props) =>
    props.active &&
    `
      max-height: 0;
    `}
`
