import * as React from 'react'

import * as S from './styled'

const RepairDetailComponent = (props) => {
  const handleNavigation = (navigateTo) => {
    props.onNavigate(navigateTo)
  }

  const handleCloseRepairDetail = () => {
    props.onCloseRepairDetail()
  }

  const frontalRef = React.useRef()
  const chargeRef = React.useRef()
  const batteryRef = React.useRef()
  const screenRef = React.useRef()
  const rearRef = React.useRef()

  React.useEffect(() => {
    if (props.activeDetail === 'frontal') {
      frontalRef.current()
    } else if (props.activeDetail === 'charge') {
      chargeRef.current()
    } else if (props.activeDetail === 'battery') {
      batteryRef.current()
    } else if (props.activeDetail === 'screen') {
      screenRef.current()
    } else if (props.activeDetail === 'rear') {
      rearRef.current()
    }
  }, [props.activeDetail])

  return (
    <S.RepairDetailWrapper
      isRepairOpen={props.isRepairOpen}
      className="fixed-element"
    >
      <S.RepairDetailFrontalCamera
        active={props.activeDetail === 'frontal'}
        isExtremeRepair={props.isExtremeRepair}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        ref={frontalRef}
        content={
          props.repairsDetails && props.repairsDetails['frontalCamera']
            ? props.repairsDetails['frontalCamera']
            : {}
        }
      />
      <S.RepairDetailChargeConector
        active={props.activeDetail === 'charge'}
        isExtremeRepair={props.isExtremeRepair}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        ref={chargeRef}
        content={
          props.repairsDetails && props.repairsDetails['chargeConector']
            ? props.repairsDetails['chargeConector']
            : {}
        }
      />
      <S.RepairDetailBatteryChange
        active={props.activeDetail === 'battery'}
        isExtremeRepair={props.isExtremeRepair}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        ref={batteryRef}
        content={
          props.repairsDetails && props.repairsDetails['batteryChange']
            ? props.repairsDetails['batteryChange']
            : {}
        }
      />
      <S.RepairDetailBrokenScreen
        active={props.activeDetail === 'screen'}
        isExtremeRepair={props.isExtremeRepair}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        ref={screenRef}
        content={
          props.repairsDetails && props.repairsDetails['brokenScreen']
            ? props.repairsDetails['brokenScreen']
            : {}
        }
      />
      <S.RepairDetailRearCamera
        active={props.activeDetail === 'rear'}
        isExtremeRepair={props.isExtremeRepair}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        ref={rearRef}
        content={
          props.repairsDetails && props.repairsDetails['rearCamera']
            ? props.repairsDetails['rearCamera']
            : {}
        }
      />
    </S.RepairDetailWrapper>
  )
}

export default RepairDetailComponent
