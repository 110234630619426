import * as React from 'react'
import axios from 'axios'
import styled, { createGlobalStyle } from 'styled-components'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import { useStaticQuery, graphql } from 'gatsby'

import GlobalStyle from '../styles/global'

import HomeRepairsComponent from '../components/home/repairs'
import HomeSupportComponent from '../components/home/support'

import AboutComponent from '../components/home/about'
import MenuComponent from '../components/menu'
import MenuMobileComponent from '../components/menu-mobile'
import TestimonialsComponent from '../components/home/testimonials'
import AdressesComponent from '../components/home/adresses'
import RepairDetail from '../components/repairDetail'

import SocialIconWhatsapp from '../images/menu/social-icon-whatsapp.svg'
import IconRepairs from '../images/menu/icon-repairs.svg'
import IconSupport from '../images/menu/icon-support.svg'
import IconAbout from '../images/menu/icon-about.svg'
import IconTestimonials from '../images/menu/icon-testimonials.svg'
import IconContact from '../images/menu/icon-contact.svg'

import media from '../styles/media-query'

const SectionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  ${media.greaterThan('sm')`
    padding-left: 96px;
  `}
`

const ViewportElement = styled.div`
  height: 100vh;
`

const LoadingComponent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100vh;
  background: #141930;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  > img {
    width: 300px;
    max-width: 40%;
  }
`

const WhatsappButton = styled.div`
  user-select: none;
  position: absolute;
  border: 0;
  right: 14px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: #61C76D;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translate3d(0, calc(100vh - 174px), 0);
  padding-right: 13px;
  z-index: 9999;
  a {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    svg {
      position: relative;
      z-index: 10;
      background-color: #61C76D;
    }
    > span {
      display: none;
    }
  }
  ${media.greaterThan('720px')`
    transform: translate3d(0, calc(100vh - 94px), 0);
    right: 34px;
    a {
      &:before {
        content: '';
        width: 60px;
        height: 60px;
        display: block;
        background-color: #61C76D;
        border-radius: 60px;
        position: absolute;
        z-index: 8;
        right: 0;
        top: 0;
      }
      > span {
        white-space: nowrap;
        margin-right: 13px;
        margin-right: -40px;
        display: flex;
        align-items: center;
        background-color: #61C76D;
        border-radius: 60px;
        font-size: 16px;
        height: 60px;
        overflow: hidden;
        transition: 600ms;
        width: 0;
        padding-right: 56px;
        span {
          padding: 0 0 0 16px;
        }
      }
      &:hover {
        > span {
          width: 252px;
        }
      }
    }
  `}
`

const BlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const IndexPage = () => {
  const {
    coroa,
  } = useStaticQuery(
    graphql`
      query {
        coroa: file(name: { eq: "coroa_final" }) {
          publicURL
        }
      }
    `)
  const sections = React.useRef(null)
  const [activeSection, setActiveSection] = React.useState('reparos')
  const [isDarkMenu, setIsDarkMenu] = React.useState(false)
  const [data, setData] = React.useState(null)

  const [currentStep, setCurrentStep] = React.useState(0)
  const [currentStepTypo, setCurrentStepTypo] = React.useState(null)
  const [showDetails, setShowDetails] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const animationImagesLength = 29
  const animationImagesHoldSize = 10
  const animationTypoSize = 24
  const animationStepsSize =
    animationImagesLength + animationImagesHoldSize + animationTypoSize
  let scrollSize = 100
  let animationHeight = scrollSize * animationStepsSize
  const [aHeight] = React.useState(animationHeight)

  React.useEffect(() => {
    console.clear()

    setLoading(true);
    class ModalPlugin extends ScrollbarPlugin {
      static pluginName = 'modal';

      static defaultOptions = {
        open: false,
      };

      transformDelta(delta) {
        return this.options.open ? { x: 0, y: 0 } : delta
      }
    }

    class DisableScrollPlugin extends ScrollbarPlugin {
      static pluginName = 'disableScroll';

      static defaultOptions = {
        direction: null,
      };

      transformDelta(delta) {
        if (this.options.direction) {
          delta[this.options.direction] = 0;
        }

        return { ...delta };
      }
    }

    axios.get('/wordpress/wp-json/reido-api/front-page').then((result) => {
      console.log(result.data)
      setData(result.data)
    }).then(() => {
      const scroller = document.querySelector('.asscroll-container')

      Scrollbar.use(ModalPlugin)
      Scrollbar.use(DisableScrollPlugin);
      const bodyScrollBar = Scrollbar.init(scroller, {
        damping: 0.1,
        delegateTo: document,
        alwaysShowTracks: true
      })

      gsap.registerPlugin(ScrollTrigger)

      ScrollTrigger.scrollerProxy('.asscroll-container', {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value
          }
          return bodyScrollBar.scrollTop
        }
      })

      bodyScrollBar.addListener(ScrollTrigger.update)
      bodyScrollBar.track.xAxis.element.remove()
      bodyScrollBar.track.autoHideOnIdle()
      bodyScrollBar.updatePluginOptions('disableScroll', { direction: 'x' })

      ScrollTrigger.defaults({ scroller: scroller })

      if (document.querySelector('.gsap-marker-scroller-start')) {
        const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

        bodyScrollBar.addListener(({ offset }) => {
          gsap.set(markers, { marginTop: -offset.y })
        })
      }

      gsap.set('.reparos-reido-text, .reparos-iphone-text', {
        x: 0,
        opacity: 1
      })

      const checkCurrentSection = (pos) => {
        const currentSection = sectionsMenu.find(({ hash }) => {
          const element = document.getElementById(hash)
          return pos + 100 <= element.offsetTop + element.clientHeight
        })
        if (!currentSection) return
        setIsDarkMenu(!!currentSection.darkMenu)
        setActiveSection(currentSection.hash)
      }

      bodyScrollBar.addListener((scrollPos) => {
        const scrollTop = scrollPos.offset.y

        Array.from(document.getElementsByClassName('fixed-element')).forEach(
          (element) => {
            element.style.top = scrollTop + 'px'
          }
        )

        checkCurrentSection(scrollTop)

        const toBeCurrentStep = Math.floor(
          scrollTop / (animationHeight / animationStepsSize)
        )
        if (toBeCurrentStep <= animationTypoSize) {
          setCurrentStep(0)
          setShowDetails(false)
          window.closeMenu()
          return
        }
        if (toBeCurrentStep - animationTypoSize >= animationImagesLength) {
          setCurrentStep(animationImagesLength - 1)
          setShowDetails(true)
          window.showMenu()
          return
        }
        setCurrentStepTypo(animationTypoSize + 1)
        setShowDetails(false)
        setCurrentStep(toBeCurrentStep - animationTypoSize)
        window.closeMenu()
      })

      gsap.to('.reparos-container', {
        scrollTrigger: {
          pin: true,
          trigger: '.reparos-scroll',
          start: 'top',
          end: animationHeight,
          scrub: true
        }
      })

      gsap.to('.reparos-reido-text', {
        xPercent: -800,
        scrollTrigger: {
          trigger: '.reparos-reido-text',
          start: 'top',
          end: animationHeight,
          scrub: true
        }
      })
      gsap.to('.reparos-iphone-text', {
        xPercent: 800,
        scrollTrigger: {
          trigger: '.reparos-reido-text',
          start: 'top',
          end: animationHeight,
          scrub: true
        }
      })

      gsap.set('.reparos-assistencia-text', { marginLeft: '-170vw' })
      gsap.to('.reparos-assistencia-text', {
        xPercent: 800,
        scrollTrigger: {
          trigger: '.reparos-reido-text',
          start: 'top',
          end: animationHeight,
          scrub: true
        }
      })

      gsap.set('.reparos-tecnica-text', { marginLeft: '130vw' })
      gsap.to('.reparos-tecnica-text', {
        xPercent: -800,
        scrollTrigger: {
          trigger: '.reparos-reido-text',
          start: 'top',
          end: animationHeight,
          scrub: true
        }
      })

      function animateFrom(elem, direction) {
        direction = direction | 1

        var x = 0,
          y = direction * 100

        const mediaWidth =
          window.innerWidth > 0 ? window.innerWidth : screen.width
        const isMobile = mediaWidth <= 980
        if (!isMobile) {
          if (elem.classList.contains('gs_reveal_fromLeft')) {
            x = -100
            y = 0
          } else if (elem.classList.contains('gs_reveal_fromRight')) {
            x = 100
            y = 0
          }
        }
        gsap.fromTo(
          elem,
          { x: x, y: y, autoAlpha: 0 },
          {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto'
          }
        )
      }

      function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 })
      }

      gsap.utils.toArray('.gs_reveal').forEach(function (elem) {
        hide(elem)

        ScrollTrigger.create({
          trigger: elem,
          onEnter: function () {
            animateFrom(elem)
          },
          onEnterBack: function () {
            animateFrom(elem, -1)
          },
          onLeave: function () {
            hide(elem)
          }
        })
      })

      function hideTicket(elem) {
        const mediaWidth =
          window.innerWidth > 0 ? window.innerWidth : screen.width
        const isMobile = mediaWidth <= 759

        if (isMobile) {
          gsap.set(elem, { x: 0, y: -200 })
          return
        }
        gsap.set(elem, { x: -300, y: 0 })
      }

      function animateTicketFrom(elem) {
        const mediaWidth =
          window.innerWidth > 0 ? window.innerWidth : screen.width
        const isMobile = mediaWidth <= 759
        if (isMobile) {
          gsap.fromTo(
            elem,
            { x: 0, y: -200 },
            {
              duration: 3,
              x: 0,
              y: 0,
              ease: 'expo',
              overwrite: 'auto'
            }
          )
          return
        }

        gsap.fromTo(
          elem,
          { x: -300, y: 0 },
          {
            duration: 3,
            x: 0,
            y: 0,
            ease: 'expo',
            overwrite: 'auto'
          }
        )
      }

      gsap.utils.toArray('.ticket > div').forEach(function (elem) {
        hideTicket(elem)

        ScrollTrigger.create({
          trigger: elem,
          onEnter: function () {
            animateTicketFrom(elem)
          },
          onEnterBack: function () {
            animateTicketFrom(elem)
          },
          onLeave: function () {
            hideTicket(elem)
          }
        })
      })

      gsap.utils.toArray('.about-background').forEach(function (elem) {
        elem.style.backgroundPosition = `50% ${-innerHeight / 2}px`

        gsap.to(elem, {
          backgroundPosition: `50% ${innerHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: elem,
            scrub: true
          }
        })
      })

      window.scrollIntoSection = (section, repairs = false, duration = 1000) => {
        if (repairs) {
          bodyScrollBar.scrollTo(
            0,
            section.clientHeight - document.documentElement.clientHeight,
            1000
          )
          return
        }
        bodyScrollBar.scrollTo(0, section.offsetTop, duration)
      }
      window.disableScroll = () => {
        bodyScrollBar.updatePluginOptions('modal', { open: true })
      }
      window.enableScroll = () => {
        bodyScrollBar.updatePluginOptions('modal', { open: false })
      }
      window.scrollToRepairs = () => {
        const section = document.querySelector(`#${sectionsMenu[0].hash}`)
        if (!section) return
        window.scrollIntoSection(section, true)
      }
      window.showMenu = () => {
        setHideMenu(false)
      }
      window.closeMenu = () => {
        setHideMenu(true)
      }
      window.scrollIntoStart = () => {
        bodyScrollBar.scrollTo(0, 0, 1000)
      }

      const contato = document.querySelector('#contato')

      if(window.location.search) {
        window.scrollIntoSection(contato, false, 0)
        window.history.pushState({}, document.title, '/')
      }

      setLoading(false)
    })
  }, [sections])

  const [hideMenu, setHideMenu] = React.useState(true)

  const sectionsMenu = [
    {
      hash: 'reparos',
      title: 'Reparos',
      icon: <IconRepairs />
    },
    {
      hash: 'atendimento',
      title: 'Atendimento',
      icon: <IconSupport />,
      darkMenu: true
    },
    {
      hash: 'nossa-historia',
      title: 'Nossa história',
      icon: <IconAbout />
    },
    {
      hash: 'depoimentos',
      title: 'Depoimentos',
      icon: <IconTestimonials />,
      darkMenu: true
    },
    {
      hash: 'contato',
      title: 'Contato',
      icon: <IconContact />
    }
  ]

  return (
    <main>
      <GlobalStyle />
      <BlockScroll />
      <MenuComponent
        hide={hideMenu}
        sections={sectionsMenu}
        activeSection={activeSection}
        isDarkMenu={isDarkMenu}
        socialLinks={data ? data.socialLinks : {}}
      />
      <ViewportElement className="asscroll-container">
        <SectionsWrapper id="sections" data-scroll-container ref={sections}>
          {loading && <LoadingComponent>
              <img src={coroa.publicURL} alt="Carregando" />
            </LoadingComponent>}
          <MenuMobileComponent
            sections={sectionsMenu}
            activeSection={activeSection}
            data-scroll-section
            socialLinks={data ? data.socialLinks : {}}
          />
          {data && data.socialLinks['whatsapp'] && (
            <WhatsappButton className="fixed-element">
              <a
                href={data.socialLinks['whatsapp']}
                target="_blank"
                rel="noreferrer"
              >
                <span><span>Em que posso ajudar?</span></span>
                <SocialIconWhatsapp />
              </a>
            </WhatsappButton>
          )}
          <HomeRepairsComponent
            animationHeight={aHeight}
            currentStep={currentStep}
            currentStepTypo={currentStepTypo}
            showDetails={showDetails}
            data-scroll-section
            repairsDetails={data ? data.repairsDetails : {}}
            repairsAccordion={data ? data.repairsAccordion : []}
          />
          <HomeSupportComponent
            data-scroll-section
            supportContent={data ? data.support : []}
          />

          <AboutComponent
            data-scroll-section
            aboutBackground={data ? data.about.videoCover : ''}
            aboutContent={data ? data.about.videoUrl : ''}
          />
          <TestimonialsComponent
            data-scroll-section
            testimonialsContent={data ? data.testimonials : []}
          />
          <AdressesComponent data-scroll-section />
          <RepairDetail data-scroll-section activeDetail="frontal" />
        </SectionsWrapper>
      </ViewportElement>
    </main>
  )
}

export default IndexPage
