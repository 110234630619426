import styled from 'styled-components'

import ContactComponent from '../contact/index'
import IconWhatsapp from '../../../images/icons/icon-whatsapp.svg'

import media from '../../../styles/media-query'

export const AdressesWrapper = styled.section`
  background: linear-gradient(
    -70deg,
    #386ba1 27.25%,
    #253556 61.12%,
    #141930 95.63%
  );
  padding: 100px;
  position: relative;
  color: #ffffff;
  ${media.greaterThan('sm')`
    min-height: 100vh;
  `}
  ${media.lessThan('md')`
    padding: 50px 100px;
  `}
  ${media.lessThan('760px')`
    padding: 50px 25px 0 25px;
  `}
`

export const AdressesContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
  ${media.lessThan('md')`
    grid-template-columns: 1fr;
  `}
`

export const AdressesContactComponent = styled(ContactComponent)`
  margin-top: -26px;
  ${media.lessThan('md')`
    display: none;
  `}
`

export const AdressesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`

export const AdressesHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AdressesTitle = styled.h2`
  font-size: 2.25em;
  margin-bottom: 76px;
  ${media.lessThan('md')`
    font-size: 1.5em;
    margin-bottom: 32px;
    text-align: center;
  `}
  ${media.lessThan('420px')`
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  `}
`

export const AdressesWhatsappText = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  font-weight: 700;
  height: fit-content;
`

export const AdressesWhatsappTextDesktop = styled(AdressesWhatsappText)`
  ${media.lessThan('md')`
    display: none;
  `}
`

export const AdressesWhatsappTextMobile = styled(AdressesWhatsappText)`
  align-self: center;
  font-size: 1.25em;
  margin-top: 64px;
  ${media.greaterThan('md')`
    display: none;
  `}
`

export const AdressesWhatsappIcon = styled(IconWhatsapp)`
  margin-right: 16px;
`

const AdressesButton = styled.button`
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1em;
  padding: 16px 32px;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: #386ba1;
  }
  ${media.greaterThan('md')`
    display: none;
  `}
`

export const AdressesButtonMobile = styled(AdressesButton)`
  margin: 40px 0;
  width: 100%;
  font-size: 1.5em;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1em;
  padding: 16px 32px;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: #386ba1;
  }
  ${media.greaterThan('md')`
    display: none;
  `}
`

export const AdressesSelectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
`

export const AdressesSelectionSelector = styled.address`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  padding: 16px;
  position: relative;
  transition: all 0.3s;

  .mobile {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    background: #ffffff;
    width: 16px;
    height: 16px;
    opacity: 0;
    top: -8px;
    left: 50%;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
    transition: all 0.3s;
  }

  ${media.greaterThan('md')`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `}
  ${media.lessThan('md')`
    padding: 8px 16px 4px 16px;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  `}

    ${(props) =>
    props.active &&
    `
      background-color: #ffffff;
      color: #253556;
      border-radius: 4px;

      &:before {
        opacity: 1;
      }
    `}
  ${media.lessThan('420px')`
    font-size: 0.875em;
  `}
`

export const AdressesSelectionSelectorTitle = styled.h3`
  font-size: 0.875em;
  font-weight: 700;
`

export const AdressesSelectionSelectorLocation = styled.span`
  font-size: 0.875em;
`

export const AdressesSelectionSelectorCity = styled.span`
  font-size: 0.875em;
`

export const AdressesMap = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  width: 100%;
  height: 170px;

  ${media.greaterThan('820px')`
    height: 300px;
  `}

  ${media.greaterThan('md')`
    height: 500px;
  `}
`

export const AdressesMapDesktop = styled(AdressesMap)`
  ${media.lessThan('md')`
    display: none;
  `}
`

export const AdressesMapMobile = styled(AdressesMap)`
  ${media.greaterThan('md')`
    display: none;
  `}
`
