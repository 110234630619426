import styled from 'styled-components'

import media from '../../../styles/media-query'

import IconPlay from '../../../images/icons/icon-play.svg'
import IconRight from '../../../images/icons/icon-arrow-right.svg'
import IconLeft from '../../../images/icons/icon-arrow-left.svg'

export const TestimonialsWrapper = styled.section`
  color: #253556;
  padding: 50px 0;
  ${media.greaterThan('sm')`
    padding: 100px;
  `}
`

export const TestimonialContentContainer = styled.div`
  display: grid;
  max-width: 1200px;
  gap: 16px;
  padding: 25px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;

  ${media.greaterThan('sm')`
    gap: 40px;
    padding: 0;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas:
      'longimage firsttext thirdimage fourthimage'
      'longimage secondimage secondtext fifthimage';
    .longimage {
      grid-area: longimage;
    }

    .firsttext {
      grid-area: firsttext;
    }

    .thirdimage {
      grid-area: thirdimage;
    }

    .fourthimage {
      grid-area: fourthimage;
    }

    .secondimage {
      grid-area: secondimage;
    }

    .secondtext {
      grid-area: secondtext;
    }

    .mobile-only {
      display: none;
    }
  `}
`

export const TestimonialsTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan('sm')`
    font-size: 1.5em;
  `}
`

export const TestimonialsImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  .gatsby-image-wrapper {
    height: 100%;
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

export const TestimonialsImagePlayIcon = styled(IconPlay)`
  height: 80px;
  width: 80px;
  ${media.lessThan('sm')`
    height: 50px;
    width: 50px;
  `}
`

export const TestimonialsImageLeft = styled(IconLeft)`
  height: 60px;
  width: 60px;
`

export const TestimonialsImageRight = styled(IconRight)`
  height: 60px;
  width: 60px;
`

export const TestimonialsTextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 60px;
  ${(props) =>
    props.firstText &&
    `
      margin: 0;
    `}

  ${media.greaterThan('md')`
    padding-left: 10px;
  `}

  ${media.greaterThan('lg')`
    ${(props) =>
      props.firstText &&
      `
      padding-left: 10px;
      margin-right: -10px;
    `}
  `}

  ${media.lessThan('lg')`
    ${(props) =>
      !props.firstText &&
      `
      margin-left: 6px;
      margin-right: -10px;
    `}
  `}
`

export const TestimonialsTextSubtitle = styled.h3``

export const TestimonialsTextParagraph = styled.p`
  margin-top: 12px;
`

export const TestimonialsTextQuoteAuthor = styled.span`
  font-weight: 600;
  margin-top: 12px;
`

export const TestimonialsTextQuotationMarksUpper = styled.span`
  > svg {
    transform: translate3d(-100%, 0, 0);
    height: 32px;
    width: auto;
  }
`

export const TestimonialsTextQuotationMarksLower = styled.span`
  margin-left: auto;
  > svg {
    height: 32px;
    width: auto;
  }
`

export const TestimonialVideoWrapper = styled.div`
  position: absolute;
  left: 94px;
  width: calc(100% - 94px);
  ${media.lessThan('sm')`
    width: 100%;
    left: 0;
  `}
  height: 100vh;
  background: linear-gradient(
    -70deg,
    #386ba1 27.25%,
    #253556 61.12%,
    #141930 95.63%
  );
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  ${(props) =>
    props.show &&
    `
    opacity: 1;
    transform: translate3d(0, 0, 0);
  `}
`

export const TestimonialVideo = styled.div`
  height: 100%;
  width: 100%;
`

export const TestimonialVideoClose = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  background-color: rgba(56, 107, 161, 0.75);
  height: 80px;
  width: 80px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  transition: 300ms;
  > svg rect {
    transition: 300ms;
  }
  ${media.lessThan('sm')`
    top: 12px;
    left: 12px;
    width: 50px;
    height: 50px;
    > svg {
      width: 25px;
      height: 25px;
    }
  `}
  &:hover,
  &:focus {
    background-color: #fff;
    > svg rect {
      fill: rgb(56, 107, 161);
    }
  }
`

export const TestimonialVideoLeft = styled(TestimonialVideoClose)`
  top: 50%;
  transform: translate3d(0, -50%, 0);
  height: 60px;
  width: 60px;
  background: transparent !important;

  ${media.lessThan('sm')`
    left: 12px;
    width: 50px;
    height: 50px;
    > svg {
      width: 50px;
      height: 50px;
    }
  `}
`

export const TestimonialVideoRight = styled(TestimonialVideoLeft)`
  top: 50%;
  left: initial;
  right: 20px;
  height: 60px;
  width: 60px;
  background: transparent !important;
  ${media.lessThan('sm')`
    width: 50px;
    height: 50px;
    left: initial;
    right: 12px;
  `}
`

export const TestimonialsCoverImg = styled.img`
  height: 100%;
`
