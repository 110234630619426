/* eslint-disable no-unused-vars */
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import HamburgersStyle from '../../styles/hamburgers'

import SocialIconFacebook from '../../images/menu/social-icon-facebook.svg'
import SocialIconInstagram from '../../images/menu/social-icon-instagram.svg'
import SocialIconTiktok from '../../images/menu/social-icon-tiktok.svg'
import SocialIconWhatsapp from '../../images/menu/social-icon-whatsapp.svg'
import SocialIconFacebookFill from '../../images/menu/social-icon-facebook-fill.svg'
import SocialIconInstagramFill from '../../images/menu/social-icon-instagram-fill.svg'
import SocialIconTiktokFill from '../../images/menu/social-icon-tiktok-fill.svg'
import SocialIconWhatsappFill from '../../images/menu/social-icon-whatsapp-fill.svg'

import * as S from './styled'

const MenuMobileComponent = ({
  sections,
  activeSection,
  isDarkMenu,
  socialLinks
}) => {
  function clickEvent(href) {
    window.enableScroll()
    setShowCollapse(false)
    const section = document.querySelector(href)
    if (!section) return
    window.scrollIntoSection(section, href === '#reparos')
  }

  function toggleMenu() {
    if (showCollapse) {
      window.enableScroll()
    } else {
      window.disableScroll()
    }
    setShowCollapse(!showCollapse)
  }

  const { logoDark, logoLight } = useStaticQuery(
    graphql`
      query {
        logoDark: file(relativePath: { eq: "menu/logo-dark.png" }) {
          childImageSharp {
            fixed(height: 42) {
              ...GatsbyImageSharpFixed_noBase64
            }
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        logoLight: file(relativePath: { eq: "menu/logo-mobile.png" }) {
          childImageSharp {
            fixed(height: 42) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )

  const [showCollapse, setShowCollapse] = React.useState(false)

  React.useEffect(() => {
    const menuToggler = document.getElementsByClassName('menu-toggler-button')
    Array.from(menuToggler).forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault()
      })
    })
  })

  return (
    <S.MenuWrapper showCollapse={showCollapse}>
      <HamburgersStyle />
      <S.MenuBrand>
        <S.MenuBrandImage isDarkMenu={isDarkMenu} className="menu-link-start">
          <div className="logo-mobile">
            <Img fixed={logoLight.childImageSharp.fixed} />
          </div>
        </S.MenuBrandImage>
      </S.MenuBrand>
      <S.MenuCollapeButton
        className={`menu-toggler-button hamburger hamburger--squeeze${
          showCollapse ? ' is-active' : ''
        }`}
        type="button"
        aria-label="Menu"
        aria-controls="navigation"
        onClick={(e) => {
          e.preventDefault()
          toggleMenu()
        }}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
        <span className="visually-hidden">Alternar visualização</span>
      </S.MenuCollapeButton>
      <S.MenuCollapse showCollapse={showCollapse}>
        <S.MenuMobileLogo className="menu-link-start">
          <Img fluid={logoDark.childImageSharp.fluid} />
        </S.MenuMobileLogo>
        <S.MenuItemsWrapper>
          <S.MenuItems>
            {sections.map((a) => (
              <S.MenuItem key={a.hash} hideSm={!!a.hideSm}>
                <a
                  href={`#${a.hash}`}
                  className={`menu-link ${
                    activeSection === a.hash ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    clickEvent(`#${a.hash}`)
                  }}
                >
                  <S.MenuItemIconWrapper>{a.icon}</S.MenuItemIconWrapper>
                  <S.MenuItemText>{a.title}</S.MenuItemText>
                </a>
              </S.MenuItem>
            ))}
          </S.MenuItems>
        </S.MenuItemsWrapper>
        <S.MenuSocial>
          <S.MenuSocialItems>
            {socialLinks['whatsapp'] && socialLinks['enableWhatsappOnMenu'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['whatsapp']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconWhatsapp />
                  <SocialIconWhatsappFill className="social-icon-fill" />
                  <span className="visually-hidden">Whatsapp</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['tiktok'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['tiktok']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconTiktok />
                  <SocialIconTiktokFill className="social-icon-fill" />
                  <span className="visually-hidden">Tiktok</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['facebook'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['facebook']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconFacebook />
                  <SocialIconFacebookFill className="social-icon-fill" />
                  <span className="visually-hidden">Facebook</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['instagram'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['instagram']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconInstagram />
                  <SocialIconInstagramFill className="social-icon-fill" />
                  <span className="visually-hidden">Instagram</span>
                </a>
              </S.MenuSocialItem>
            )}
          </S.MenuSocialItems>
        </S.MenuSocial>
      </S.MenuCollapse>
    </S.MenuWrapper>
  )
}

export default MenuMobileComponent
