import styled from 'styled-components'

import media from '../../../styles/media-query'

export const AboutWrapper = styled.div``

export const AboutImgContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 100vh;
  background-color: #253556;
  overflow: hidden;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .about-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.backgroundImage});
  }
`

export const AboutTitle = styled.h2`
  background: #141930;
  color: #ffffff;

  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  ${media.greaterThan('sm')`
    display: none;
  `}
  ${media.lessThan('sm')`
    font-size: 1.5em;
  `}
`

export const AboutVideoWrapper = styled.div`
  position: absolute;
  width: calc(100% - 94px);
  ${media.lessThan('sm')`
    width: 100%;
  `}
  height: 100vh;
  background: linear-gradient(
    -70deg,
    #386ba1 27.25%,
    #253556 61.12%,
    #141930 95.63%
  );
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  ${(props) =>
    props.show &&
    `
    opacity: 1;
    transform: translate3d(0, 0, 0);
  `}
`

export const AboutVideo = styled.div`
  width: 100%;
  height: 100%;
`

export const AboutVideoClose = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  background-color: rgba(56, 107, 161, 0.75);
  height: 80px;
  width: 80px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  transition: 300ms;
  > svg rect {
    transition: 300ms;
  }
  ${media.lessThan('sm')`
    top: 12px;
    left: 12px;
    width: 50px;
    height: 50px;
    > svg {
      width: 25px;
      height: 25px;
    }
  `}
  &:hover,
  &:focus {
    background-color: #fff;
    > svg rect {
      fill: rgb(56, 107, 161);
    }
  }
`
