import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ServicesComponent from '../../services'
import RepairDetail from '../../repairDetail'

import RepairDecorationScreen from '../../../images/home/repairs/decorations/repair-decoration-screen.svg'
import RepairDecorationCamera from '../../../images/home/repairs/decorations/repair-decoration-camera.svg'
import RepairDecorationConector from '../../../images/home/repairs/decorations/repair-decoration-conector.svg'
import RepairDecorationBattery from '../../../images/home/repairs/decorations/repair-decoration-battery.svg'
import RepairDecorationBackCamera from '../../../images/home/repairs/decorations/repair-decoration-back-camera.svg'

import RepairDecorationScreenMobile from '../../../images/home/repairs/decorations/mobile/repair-decoration-screen.svg'
import RepairDecorationCameraMobile from '../../../images/home/repairs/decorations/mobile/repair-decoration-camera.svg'
import RepairDecorationConectorMobile from '../../../images/home/repairs/decorations/mobile/repair-decoration-conector.svg'
import RepairDecorationBatteryMobile from '../../../images/home/repairs/decorations/mobile/repair-decoration-battery.svg'
import RepairDecorationBackCameraMobile from '../../../images/home/repairs/decorations/mobile/repair-decoration-back-camera.svg'

import ReiDoText from '../../../images/home/cover/rei-do.svg'
import IphoneText from '../../../images/home/cover/iphone.svg'
import AssistenciaText from '../../../images/home/cover/assistencia.svg'
import TecnicaText from '../../../images/home/cover/tecnica.svg'

import * as S from './styled'

const HomeRepairsComponent = ({
  animationHeight,
  showDetails,
  currentStep,
  repairsAccordion,
  repairsDetails
}) => {
  const [isServicesOpen, setIsServicesOpen] = React.useState(false)

  const [isRepairOpen, setIsRepairOpen] = React.useState('')

  const [marginTopButton, setMarginTopButton] = React.useState('')

  function toggleServicesOverlay(isServicesToOpen) {
    setIsServicesOpen(isServicesToOpen)
    if (isServicesToOpen) {
      window.scrollToRepairs()
      window.disableScroll()
      return
    }
    window.enableScroll()
  }

  function toggleRepairOverlay(isRepairToOpen) {
    setIsRepairOpen(isRepairToOpen)

    if (isRepairToOpen) {
      window.scrollToRepairs()
      window.disableScroll()
      return
    }
    window.enableScroll()
  }

  function clickScrollDownIcon() {
    window.scrollToRepairs()
  }

  React.useEffect(() => {
    const links = document.getElementsByClassName('menu-link')
    Array.from(links).forEach((link) => {
      link.addEventListener('click', () => {
        if (!isServicesOpen && !isRepairOpen) return
        toggleRepairOverlay('')
        setIsServicesOpen(false)
        window.enableScroll()
      })
    })

    const linksStart = document.getElementsByClassName('menu-link-start')
    Array.from(linksStart).forEach((link) => {
      link.addEventListener('click', () => {
        if (!isServicesOpen && !isRepairOpen) return
        toggleRepairOverlay('')
        setIsServicesOpen(false)
        window.enableScroll()
      })
    })

    const mediaWidth = window.innerWidth > 0 ? window.innerWidth : screen.width
    const isMobile = mediaWidth <= 980
    if (isMobile) {
      if (window.scrollY > 100) {
        setMarginTopButton('')
      } else {
        setMarginTopButton(`-${100 - window.scrollY}px`)
      }
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) return
        setMarginTopButton(`-${100 - window.scrollY}px`)
      })
    }
  })

  const handleNavigation = (navigateTo) => {
    if (navigateTo === 'previous' && currentActiveRepair - 1 >= 0) {
      toggleRepairOverlay(repairArray[currentActiveRepair - 1])
      return
    }
    if (navigateTo === 'next' && currentActiveRepair + 1 < repairArray.length) {
      toggleRepairOverlay(repairArray[currentActiveRepair + 1])
    }
  }

  const handleCloseRepairDetail = () => {
    toggleRepairOverlay('')
  }

  const repairArray = ['frontal', 'charge', 'battery', 'screen', 'rear']

  const currentActiveRepair = repairArray.findIndex(
    (repair) => repair === isRepairOpen
  )

  const isExtremeCalculator = () => {
    if (currentActiveRepair === 0) return 'first'
    if (currentActiveRepair === repairArray.length - 1) return 'last'
    return ''
  }

  const isExtremeRepair = isExtremeCalculator()

  const { coverIphoneAnimations } = useStaticQuery(
    graphql`
      query {
        coverIphoneAnimations: file(name: { eq: "DESCE_E_ABRE_SPRITES" }) {
          childImageSharp {
            fluid(maxWidth: 7200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.HomeRepairsWrapper
        id="reparos"
        style={{
          height: `calc(100vh + ${animationHeight}px)`
        }}
      >
        <div
          className="reparos-scroll"
          style={{ height: '100%', width: '100%' }}
        >
          <S.HomeRepairsContainer className="reparos-container">
            <S.HomeRepairsHeader
              show={showDetails}
              className="home-repairs-header"
            >
              <S.HomeRepairsTitle>Reparos</S.HomeRepairsTitle>
              <S.HomeRepairsButton
                data-home-repairs-option-button
                onClick={() => toggleServicesOverlay(true)}
              >
                Todos os reparos
              </S.HomeRepairsButton>
            </S.HomeRepairsHeader>
            <S.HomeRepairsBackground
              className="reparos-animation"
              style={{
                top:
                  currentStep > 0
                    ? `${currentStep > 10 ? 45 : 50 - currentStep * 0.5}%`
                    : '50%'
              }}
            >
              <S.HomeCoverScrollIcon
                className="reparos-scroll-down-icon"
                hide={currentStep > 0}
                style={{ marginTop: marginTopButton }}
                onClick={(e) => {
                  e.preventDefault()
                  clickScrollDownIcon()
                }}
              >
                <div className="scroll-downs">
                  <div className="mousey">
                    <div className="scroller"></div>
                  </div>
                </div>
              </S.HomeCoverScrollIcon>
              <S.HomeCoverIphone>
                <S.HomeCoverLeftTitle hide={currentStep > 0}>
                  <ReiDoText className="reparos-reido-text" />
                </S.HomeCoverLeftTitle>
                <S.HomeCoverRightTitle hide={currentStep > 0}>
                  <IphoneText className="reparos-iphone-text" />
                </S.HomeCoverRightTitle>
                <S.HomeCoverLeftTitleAfter hide={currentStep > 0}>
                  <AssistenciaText
                    className="reparos-assistencia-text"
                    style={{ marginLeft: '-170vw' }}
                  />
                </S.HomeCoverLeftTitleAfter>
                <S.HomeCoverRightTitleAfter hide={currentStep > 0}>
                  <TecnicaText
                    className="reparos-tecnica-text"
                    style={{ marginLeft: '130vw' }}
                  />
                </S.HomeCoverRightTitleAfter>

                <S.HomeCoverIphoneAnimationSteps
                  currentStep={currentStep}
                  className={currentStep === 0 && `floating`}
                  background={coverIphoneAnimations.childImageSharp.fluid.src}
                >
                  <div className={`bg-step-${currentStep}`} />
                </S.HomeCoverIphoneAnimationSteps>
              </S.HomeCoverIphone>

              <S.HomeRepairsOptions
                show={showDetails}
                className={showDetails && 'animate__animated animate__fadeIn'}
              >
                <S.HomeRepairsOptionScreen>
                  <S.HomeRepairsOptionButton
                    data-home-repairs-option-button
                    onClick={() => toggleRepairOverlay('screen')}
                  >
                    Tela
                  </S.HomeRepairsOptionButton>
                  <S.HomeRepairsOptionScreenDecoration>
                    <RepairDecorationScreen />
                    <RepairDecorationScreenMobile />
                  </S.HomeRepairsOptionScreenDecoration>
                </S.HomeRepairsOptionScreen>

                <S.HomeRepairsOptionCamera>
                  <S.HomeRepairsOptionCameraDecoration>
                    <RepairDecorationCamera />
                    <RepairDecorationCameraMobile />
                  </S.HomeRepairsOptionCameraDecoration>
                  <S.HomeRepairsOptionButton
                    data-home-repairs-option-button
                    onClick={() => toggleRepairOverlay('frontal')}
                  >
                    Câmera frontal
                  </S.HomeRepairsOptionButton>
                </S.HomeRepairsOptionCamera>

                <S.HomeRepairsOptionConector>
                  <S.HomeRepairsOptionButton
                    data-home-repairs-option-button
                    onClick={() => toggleRepairOverlay('charge')}
                  >
                    Conector de carga
                  </S.HomeRepairsOptionButton>
                  <S.HomeRepairsOptionConectorDecoration>
                    <RepairDecorationConector />
                    <RepairDecorationConectorMobile />
                  </S.HomeRepairsOptionConectorDecoration>
                </S.HomeRepairsOptionConector>

                <S.HomeRepairsOptionBattery>
                  <S.HomeRepairsOptionBatteryDecoration>
                    <RepairDecorationBattery />
                    <RepairDecorationBatteryMobile />
                  </S.HomeRepairsOptionBatteryDecoration>
                  <S.HomeRepairsOptionButton
                    data-home-repairs-option-button
                    onClick={() => toggleRepairOverlay('battery')}
                  >
                    Bateria
                  </S.HomeRepairsOptionButton>
                </S.HomeRepairsOptionBattery>

                <S.HomeRepairsOptionBackCamera>
                  <S.HomeRepairsOptionBackCameraDecoration>
                    <RepairDecorationBackCamera />
                    <RepairDecorationBackCameraMobile />
                  </S.HomeRepairsOptionBackCameraDecoration>
                  <S.HomeRepairsOptionButton
                    data-home-repairs-option-button
                    onClick={() => toggleRepairOverlay('rear')}
                  >
                    Câmera traseira
                  </S.HomeRepairsOptionButton>
                </S.HomeRepairsOptionBackCamera>
              </S.HomeRepairsOptions>
            </S.HomeRepairsBackground>
            <S.HomeRepairsButtonSm
              data-home-repairs-option-button
              show={showDetails}
              className={showDetails && 'animate__animated animate__fadeIn'}
              onClick={() => toggleServicesOverlay(true)}
            >
              Todos os reparos
            </S.HomeRepairsButtonSm>
          </S.HomeRepairsContainer>
        </div>
      </S.HomeRepairsWrapper>

      <ServicesComponent
        onCloseServices={() => toggleServicesOverlay(false)}
        isServicesOpen={isServicesOpen}
        repairsAccordion={repairsAccordion}
      />
      <RepairDetail
        isExtremeRepair={isExtremeRepair}
        isRepairOpen={isRepairOpen !== ''}
        activeDetail={isRepairOpen}
        onNavigate={(navigateToFromChild) => {
          handleNavigation(navigateToFromChild)
        }}
        onCloseRepairDetail={() => {
          handleCloseRepairDetail()
        }}
        repairsDetails={repairsDetails}
      />
    </>
  )
}

export default HomeRepairsComponent
