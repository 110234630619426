import * as React from 'react'

import IconClose from '../../../images/icons/icon-close.svg'

import * as S from './styled'

const TestimonialsComponent = ({ testimonialsContent }) => {
  const [show, setShow] = React.useState(false)
  const [currentVideo, setCurrentVideo] = React.useState(0)

  const openShowVideo = (index) => {
    window.disableScroll()
    if (index !== currentVideo) {
      setCurrentVideo(index)
    }
    setShow(true)
  }

  const closeShowVideo = () => {
    setShow(false)
    window.enableScroll()
  }

  React.useEffect(() => {
    const links = document.getElementsByClassName('menu-link')
    Array.from(links).forEach((link) => {
      link.addEventListener('click', () => {
        if (!show) return
        closeShowVideo()
      })
    })

    const linksStart = document.getElementsByClassName('menu-link-start')
    Array.from(linksStart).forEach((link) => {
      link.addEventListener('click', () => {
        if (!show) return
        closeShowVideo()
      })
    })
  })

  return (
    <S.TestimonialsWrapper id="depoimentos">
      <S.TestimonialsTitle className="gs_reveal">
        Depoimentos
      </S.TestimonialsTitle>

      <S.TestimonialContentContainer>
        <S.TestimonialsImageContainer
          className="longimage"
          onClick={() => {
            openShowVideo(0)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[0] &&
              testimonialsContent[0].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="firsttext"
          firstText
          onClick={() => {
            openShowVideo(1)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[1] &&
              testimonialsContent[1].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="secondimage"
          onClick={() => {
            openShowVideo(2)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[2] &&
              testimonialsContent[2].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="thirdimage"
          onClick={() => {
            openShowVideo(3)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[3] &&
              testimonialsContent[3].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="fourthimage"
          onClick={() => {
            openShowVideo(4)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[4] &&
              testimonialsContent[4].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="secondtext"
          onClick={() => {
            openShowVideo(5)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[5] &&
              testimonialsContent[5].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        <S.TestimonialsImageContainer
          className="fifthimage"
          onClick={() => {
            openShowVideo(6)
          }}
        >
          <S.TestimonialsCoverImg
            src={
              testimonialsContent &&
              testimonialsContent[6] &&
              testimonialsContent[6].cover
            }
          />
          <S.TestimonialsImagePlayIcon />
        </S.TestimonialsImageContainer>

        {testimonialsContent &&
          testimonialsContent.slice(7).map((content, index) => (
            <S.TestimonialsImageContainer
              className="mobile-only"
              key={index + 7}
              onClick={() => {
                openShowVideo(index + 7)
              }}
            >
              <S.TestimonialsCoverImg src={content && content.cover} />
              <S.TestimonialsImagePlayIcon />
            </S.TestimonialsImageContainer>
          ))}
      </S.TestimonialContentContainer>

      <S.TestimonialVideoWrapper className="fixed-element" show={show}>
        <S.TestimonialVideoClose onClick={closeShowVideo}>
          <IconClose />
        </S.TestimonialVideoClose>
        <S.TestimonialVideoLeft
          onClick={() => {
            console.log(currentVideo)
            openShowVideo(
              currentVideo === 0
                ? testimonialsContent.length - 1
                : currentVideo - 1
            )
          }}
        >
          <S.TestimonialsImageLeft />
        </S.TestimonialVideoLeft>
        <S.TestimonialVideoRight
          onClick={() => {
            console.log(currentVideo)
            openShowVideo(
              currentVideo + 1 === testimonialsContent.length
                ? 0
                : currentVideo + 1
            )
          }}
        >
          <S.TestimonialsImageRight />
        </S.TestimonialVideoRight>
        {show && (
          <S.TestimonialVideo key={currentVideo}>
            <video width="100%" height="100%" autoPlay>
              <source
                src={
                  testimonialsContent &&
                  testimonialsContent[currentVideo] &&
                  testimonialsContent[currentVideo].video
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </S.TestimonialVideo>
        )}
      </S.TestimonialVideoWrapper>
    </S.TestimonialsWrapper>
  )
}

export default TestimonialsComponent
