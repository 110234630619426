import styled from 'styled-components'

import media from '../../styles/media-query'
import IconClose from '../../images/icons/icon-close.svg'

export const RepairDetailTextIconClose = styled(IconClose)``

export const ServicesWrapper = styled.section`
  background: linear-gradient(300deg, #386ba1 7.79%, #141930 85.83%);
  color: #ffffff;
  padding: 100px 100px 100px 196px;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, -200%, 0);
  transition: all 0.3s;
  min-height: 500px;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  ${media.lessThan('sm')`
    padding: 25px;
  `}
  ${(props) =>
    props.isServicesOpen &&
    `
      opacity: 1;
      transform: translate3d(0, 0, 0);
  `}
`

export const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`

export const ServicesTitleWrapper = styled.div`
  margin-bottom: 50px;
`

export const ServicesTitle = styled.h1`
  font-size: 2.25em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const ServicesSubtext = styled.p`
  margin-right: 20%;
  font-size: 1em;
  max-width: 900px;
  line-height: 1.4;
`

export const ServicesAccordionWrapper = styled.div``

export const ServicesAccordionCloseBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 0.5em;
  font-weight: 700;
  height: 50px;
  padding: 0 32px;

  &:focus {
    outline: none;
  }

  > svg {
    margin-right: 16px;
  }
  &:hover {
    background-color: #fff;
    color: #386ba1;
    > svg {
      path {
        fill: #386ba1;
      }
    }
  }

  ${(props) =>
    props.mobile &&
    `
      padding: 0;
      width: 50px;
      display: none;
  `}

  ${(props) =>
    !props.mobile &&
    `
      display: block;
  `}

  ${media.lessThan('sm')`
    border: 0;
    > svg {
      margin-right: 0;
    }
    ${(props) =>
      props.mobile &&
      `
        display: block;
    `}

    ${(props) =>
      !props.mobile &&
      `
          display: none;
    `}
  `}
`
