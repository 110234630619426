import * as React from 'react'

import IconPlay from '../../../images/icons/icon-play.svg'
import IconClose from '../../../images/icons/icon-close.svg'

import * as S from './styled'

const AboutComponent = ({ aboutContent, aboutBackground }) => {
  const [show, setShow] = React.useState(false)

  const openShowVideo = () => {
    window.disableScroll()
    setShow(true)
  }

  const closeShowVideo = () => {
    setShow(false)
    window.enableScroll()
  }

  React.useEffect(() => {
    const links = document.getElementsByClassName('menu-link')
    Array.from(links).forEach((link) => {
      link.addEventListener('click', () => {
        if (!show) return
        closeShowVideo()
      })
    })

    const linksStart = document.getElementsByClassName('menu-link-start')
    Array.from(linksStart).forEach((link) => {
      link.addEventListener('click', () => {
        if (!show) return
        closeShowVideo()
      })
    })
  })

  return (
    <S.AboutWrapper id="nossa-historia">
      <S.AboutTitle>
        <span className="gs_reveal">Nossa História</span>
      </S.AboutTitle>
      <S.AboutImgContainer
        backgroundImage={aboutBackground}
        onClick={openShowVideo}
      >
        <div className="about-background" />
        <IconPlay width="80" height="80" />
      </S.AboutImgContainer>
      <S.AboutVideoWrapper className="fixed-element" show={show}>
        <S.AboutVideoClose onClick={closeShowVideo}>
          <IconClose />
        </S.AboutVideoClose>
        {show && (
          <S.AboutVideo>
            <video width="100%" height="100%" autoPlay>
              <source src={aboutContent} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </S.AboutVideo>
        )}
      </S.AboutVideoWrapper>
    </S.AboutWrapper>
  )
}

export default AboutComponent
