/* eslint-disable no-unused-vars */
import * as React from 'react'

import SocialIconFacebook from '../../images/menu/social-icon-facebook.svg'
import SocialIconInstagram from '../../images/menu/social-icon-instagram.svg'
import SocialIconTiktok from '../../images/menu/social-icon-tiktok.svg'
import SocialIconWhatsapp from '../../images/menu/social-icon-whatsapp.svg'
import SocialIconFacebookFill from '../../images/menu/social-icon-facebook-fill.svg'
import SocialIconInstagramFill from '../../images/menu/social-icon-instagram-fill.svg'
import SocialIconTiktokFill from '../../images/menu/social-icon-tiktok-fill.svg'
import SocialIconWhatsappFill from '../../images/menu/social-icon-whatsapp-fill.svg'
import LogoDesktop from '../../images/menu/logo-desktop.svg'
import LogoDesktopText from '../../images/menu/logo-desktop-text.svg'

import * as S from './styled'

const MenuComponent = ({
  hide,
  sections,
  activeSection,
  isDarkMenu,
  socialLinks
}) => {
  function clickEvent(href) {
    const section = document.querySelector(href)
    if (!section) return
    window.scrollIntoSection(section, href === '#reparos')
  }

  function clickStartEvent() {
    window.scrollIntoStart()
  }

  return (
    <S.MenuWrapper isDarkMenu={isDarkMenu} hide={hide}>
      <S.MenuBrand>
        <S.MenuBrandImage
          isDarkMenu={isDarkMenu}
          hide={hide}
          className="menu-link-start"
          onClick={(e) => {
            e.preventDefault()
            clickStartEvent()
          }}
        >
          <div className="logo-desktop">
            <LogoDesktop />
            <div className="logo-desktop-text">
              <LogoDesktopText />
            </div>
          </div>
        </S.MenuBrandImage>
      </S.MenuBrand>
      <S.MenuCollapse>
        <S.MenuItemsWrapper>
          <S.MenuItems>
            {sections.map((a) => (
              <S.MenuItem key={a.hash} hideSm={!!a.hideSm}>
                <a
                  href={`#${a.hash}`}
                  className={`menu-link ${
                    activeSection === a.hash ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    clickEvent(`#${a.hash}`)
                  }}
                >
                  <S.MenuItemIconWrapper>{a.icon}</S.MenuItemIconWrapper>
                  <S.MenuItemText>{a.title}</S.MenuItemText>
                </a>
              </S.MenuItem>
            ))}
          </S.MenuItems>
        </S.MenuItemsWrapper>
        <S.MenuSocial>
          <S.MenuSocialItems>
            {socialLinks['whatsapp'] && socialLinks['enableWhatsappOnMenu'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['whatsapp']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconWhatsapp />
                  <SocialIconWhatsappFill className="social-icon-fill" />
                  <span className="visually-hidden">Whatsapp</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['tiktok'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['tiktok']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconTiktok />
                  <SocialIconTiktokFill className="social-icon-fill" />
                  <span className="visually-hidden">Tiktok</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['facebook'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['facebook']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconFacebook />
                  <SocialIconFacebookFill className="social-icon-fill" />
                  <span className="visually-hidden">Facebook</span>
                </a>
              </S.MenuSocialItem>
            )}
            {socialLinks['instagram'] && (
              <S.MenuSocialItem>
                <a
                  href={socialLinks['instagram']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIconInstagram />
                  <SocialIconInstagramFill className="social-icon-fill" />
                  <span className="visually-hidden">Instagram</span>
                </a>
              </S.MenuSocialItem>
            )}
          </S.MenuSocialItems>
        </S.MenuSocial>
      </S.MenuCollapse>
    </S.MenuWrapper>
  )
}

export default MenuComponent
