import styled from 'styled-components'

import media from '../../styles/media-query'

export const MenuWrapper = styled.nav`
  --menu-background-color: #ffffff;
  --menu-text-color: #253556;
  --menu-social-color: #3274b6;

  background-color: var(--menu-background-color);
  color: var(--menu-text-color);
  user-select: none;

  transition: color 0.5s, background-color 0.5s,
    transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  z-index: 10000;
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0;
  background: transparent;
  margin-bottom: -70px;
  &:before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    opacity: 0;
    background: linear-gradient(
      rgb(56, 107, 161) 7.79%,
      rgb(20, 25, 48) 85.83%
    );
    transition: opacity 200ms;

    ${(props) =>
      props.showCollapse &&
      `
      z-index: 1;
      opacity: 1;
      height: 100vh;
    `}
  }
  ${media.greaterThan('sm')`
    display: none !important;
  `}
`

export const MenuMobileLogo = styled.div`
  display: none;
  cursor: pointer;
  left: 0;
  display: block;
  .gatsby-image-wrapper {
    max-width: 200px;
  }
`

export const MenuBrand = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuBrandImage = styled.div`
  cursor: pointer;
  padding-top: 4px;
  padding-left: 6px;
  .logo-mobile {
    display: block;
  }
`

export const MenuCollapeButton = styled.button`
  display: block;
  z-index: 10000;
  padding-top: 18px;
  .hamburger-inner,
  .hamburger-inner:before,
  .hamburger-inner:after {
    background-color: #fff;
  }
`

export const MenuCollapse = styled.div`
  display: flex;
  flex-direction: column;
  width: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  height: 100vh;
  padding: 0;
  background-color: #fff;
  transition: 200ms;
  z-index: 2;
  ${(props) =>
    props.showCollapse &&
    `
    padding: 32px 20px 20px;
    width: 350px;
    box-shadow: -4px 4px 16px rgba(1, 7, 31, 0.41);
    transform: translate3d(0, 0, 0);
  `}
`

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: auto;
  margin-bottom: auto;

  padding: 60px 30px;
  align-items: flex-start;
`

export const MenuItems = styled.ul``

export const MenuItem = styled.li`
  width: 40px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  a {
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: var(--menu-text-color);
    text-decoration: none;
    border-radius: 8px;
    transition: 200ms;
    position: relative;

    > div {
      color: currentColor;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      position: relative;
      z-index: 2;
    }
    > span {
      width: 0;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      background-color: var(--menu-text-color);
      top: 0;
      border-radius: 8px;
      bottom: 0;
      overflow: hidden;
      transition: 200ms;
      opacity: 0;
    }
    &.active {
      background-color: var(--menu-text-color);
      color: var(--menu-background-color);
    }
    ${(props) => props.hideSm && 'display: none;'}
    > span {
      display: flex;
      align-items: center;
      background-color: transparent;
      top: 0;
      bottom: 0;
      overflow: hidden;
      transition: 200ms;
      padding-left: 60px;
      padding-right: 8px;
      opacity: 1;
      color: var(--menu-text-color);
      width: auto;
    }
  }
`

export const MenuItemIconWrapper = styled.div``

export const MenuItemText = styled.span`
  overflow: hidden;
`

export const MenuSocial = styled.div`
  align-self: center;
  margin-top: auto;
  margin-bottom: 60px;
  margin-right: auto;
  margin-left: 30px;
`

export const MenuSocialItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MenuSocialItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0;
    margin-right: 20px;
  }
  a {
    display: flex;
    align-items: center;
    color: var(--menu-social-color);
    text-decoration: none;
    position: relative;
    > svg {
      transition: 600ms;
    }
    > svg.social-icon-fill {
      position: absolute;
      opacity: 0;
    }
    &:hover {
      > svg:not(.social-icon-fill) {
        opacity: 0;
      }
      > svg.social-icon-fill {
        opacity: 1;
      }
    }
  }
`
