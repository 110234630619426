import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import RepairDetailText from '../RepairDetailText/index'

import * as S from './styled'

// eslint-disable-next-line react/display-name
const BatteryChange = React.forwardRef((props, ref) => {
  const [currentStep, setCurrentStep] = React.useState(0)

  const repairTitle = 'Troca de bateria'
  const repairTitleMobile = 'Bateria'
  const repairText = props.content && props.content.description

  const handleNavigation = (navigateTo) => {
    setCurrentStep(0)
    props.onNavigate(navigateTo)
  }

  const handleCloseRepairDetail = () => {
    setCurrentStep(0)
    props.onCloseRepairDetail()
  }

  const { batteryChangeAnimations, batteryChangeMobileImg } = useStaticQuery(
    graphql`
      query {
        batteryChangeAnimations: allFile(
          filter: {
            relativePath: { regex: "/repairs/battery-change/FINAL BATERIA /" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        batteryChangeMobileImg: file(
          relativePath: {
            eq: "repairs/battery-change/battery-change-mobile.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const updateSteps = () => {
    let index = 0
    const interval = setInterval(() => {
      setCurrentStep(index)

      index++
      if (index === batteryChangeAnimations.edges.length)
        clearInterval(interval)
    }, 100)
  }

  React.useImperativeHandle(ref, () => updateSteps)

  return (
    <>
      <S.RepairDetailWrapper className={props.className}>
        <RepairDetailText
          isExtremeRepair={props.isExtremeRepair}
          title={repairTitle}
          titleMobile={repairTitleMobile}
          text={repairText}
          serviceTime={props.content && props.content.serviceTime}
          onNavigate={(navigateToFromChild) => {
            handleNavigation(navigateToFromChild)
          }}
          onCloseRepairDetail={() => {
            handleCloseRepairDetail()
          }}
        />

        {batteryChangeAnimations.edges.map((coverAnimation, index) => (
          <S.RepairDetailImage
            data-step={index}
            isCurrentStep={currentStep === index}
            key={`frontal-animation-step-${index}`}
            style={{ position: 'absolute' }}
          >
            <Img fluid={coverAnimation.node.childImageSharp.fluid} />
          </S.RepairDetailImage>
        ))}

        <S.RepairDetailImageMobile style={{ position: 'absolute' }}>
          <Img fluid={batteryChangeMobileImg.childImageSharp.fluid} />
        </S.RepairDetailImageMobile>
      </S.RepairDetailWrapper>
    </>
  )
})

export default BatteryChange
