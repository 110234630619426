import styled from 'styled-components'

import media from '../../../styles/media-query'

export const RepairDetailWrapper = styled.section`
  background: linear-gradient(300deg, #386ba1 7.79%, #141930 85.83%);
  color: #ffffff;
  display: flex;
  overflow: hidden;
  padding: 80px 100px 100px 196px;
  position: relative;
  ${media.lessThan('sm')`
    flex-direction: column;
    padding: 25px;
  `}
`

export const RepairDetailImage = styled.div`
  max-width: 1520px;
  width: 100%;
  right: 0;
  display: block;
  top: 0;
  opacity: 0;

  ${(props) =>
    props.isCurrentStep &&
    `
    opacity: 1;
  `}

  ${media.lessThan('sm')`
    display: none;
  `}
`

export const RepairDetailImageMobile = styled.div`
  width: 70%;
  right: 0;
  bottom: 0;
  display: none;

  ${media.lessThan('sm')`
    display: block;
  `}
`
